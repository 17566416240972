import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'reactstrap';
import { searchResources, resourceByTags } from '../../../../Api/lms/apiUtils';
import PreviewComponent from './PreviewComponent';
import { toast } from 'react-toastify';
import { tagsMap } from './tags';
import Select from 'react-select';

interface Resource {
  resource_id: number;
  resource_name: any;
  type: any;
  description: any;
  url: any;
  fileUrl: string;
  display_name: any;
}

const SearchComponent: React.FC<any> = ({ setResources, selectedTopic, handleAddExistingResourceToTopic }) => {
  const [query, setQuery] = useState<string>('');
  const [allResources, setAllResources] = useState<Resource[]>([]);
  const [displayedResources, setDisplayedResources] = useState<Resource[]>([]);
  const [selectedResources, setSelectedResources] = useState<Set<number>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewResource, setPreviewResource] = useState<Resource | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<'name' | 'tags'>('name');
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [tagFilteredResources, setTagFilteredResources] = useState<Resource[]>([]);
  const { tagsList, isLoading: tagsLoading } = tagsMap()
  const tagOptions = tagsList.map((tag) => ({ value: tag.id, label: tag.name }));

  const observerRef = useRef<IntersectionObserver | null>(null);

  const lastResourceRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          if (activeTab === 'name') {
            setDisplayedResources(prev => [...prev, ...allResources.slice(prev.length, prev.length + 10)]);
          }
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isLoading, allResources, activeTab]
  );

  const fetchResourcesByName = async () => {
    setIsLoading(true);
    try {
      const response = await searchResources(query, 1, 10);
      const newResources = response.results || [];
      setAllResources(newResources);
      setDisplayedResources(newResources);
    } catch (error) {
      console.error('Error fetching resources by name:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchResourcesByTags = async () => {
    if (selectedTags.length === 0) {
      setTagFilteredResources([]);
      return;
    }
    setIsLoading(true);
    try {
      const allSongs = new Set<Resource>();
      const response = await resourceByTags({
        tagIds: selectedTags,
        limit: 10,
        offset: 0,
      });
      const newResources = response.data.resources || [];
      setTagFilteredResources(newResources);
    } catch (error) {
      console.error('Error fetching resources by tags:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'name') {
      fetchResourcesByName();
    }
  }, [activeTab, query]);

  useEffect(() => {
    if (activeTab === 'tags') {
      fetchResourcesByTags();
    }
  }, [activeTab, selectedTags]);

  const handlePreviewToggle = (resource: Resource | null) => {
    setPreviewResource(resource);
    setIsPreviewOpen(!isPreviewOpen);
  };

  const toggleResourceSelection = (resource_id: number) => {
    setSelectedResources(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(resource_id)) {
        newSelected.delete(resource_id);
      } else {
        newSelected.add(resource_id);
      }
      return newSelected;
    });
  };

  const addSelectedResources = () => {
    const selected =
      activeTab === 'name'
        ? displayedResources.filter(resource => selectedResources.has(resource.resource_id))
        : tagFilteredResources.filter(resource => selectedResources.has(resource.resource_id));

    if (selectedTopic) {
      handleAddExistingResourceToTopic(selected);
      setSelectedResources(new Set());
      // toast.success('Resources added successfully');
    } else {
      setResources((prev: any) => [...prev, ...selected]);
      setSelectedResources(new Set());
      // toast.success('Resources added successfully');
    }
  };

  return (
    <div>
      {/* Tab Navigation */}
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'name' ? '#e82c86' : '#f0f0f0',
            color: activeTab === 'name' ? '#fff' : '#000',
            border: '1px solid #ccc',
          }}
          onClick={() => setActiveTab('name')}
        >
          Search by Name
        </button>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'tags' ? '#e82c86' : '#f0f0f0',
            color: activeTab === 'tags' ? '#fff' : '#000',
            border: '1px solid #ccc',
          }}
          onClick={() => setActiveTab('tags')}
        >
          Search by Tags
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'name' && (
        <div>
          <input
            type="text"
            placeholder="Search resource Name..."
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
          />
             <div style={{ height: '200px', overflowY: 'auto', border: '1px solid #ccc', marginBottom: '10px' }}>
          {displayedResources.map((resource, index) => (
            <div
              key={resource.resource_id}
              ref={index === displayedResources.length - 1 ? lastResourceRef : null}
              style={{ padding: '10px', borderBottom: '1px solid #ddd' }}
            >
              <input
                type="checkbox"
                checked={selectedResources.has(resource.resource_id)}
                onChange={() => toggleResourceSelection(resource.resource_id)}
                style={{ marginRight: '10px' }}
              />
              <h3>{resource.display_name || resource.resource_name}</h3>
            </div>
          ))}
        </div>
        </div>
      )}

      {activeTab === 'tags' && (
        <div >
        
          <div style={{marginBottom:"10px"}}> 
           <Select
            isMulti
          
            options={tagOptions}
            onChange={selectedOptions => {
              const tags = selectedOptions.map(option => option.value);
              setSelectedTags(tags);
              
            }}
            placeholder="Select tags"
       

          /></div>
        
            <div style={{ height: '200px', overflowY: 'auto', border: '1px solid #ccc', marginBottom: '10px' }}>
  {tagFilteredResources.length > 0 ? (
    tagFilteredResources.map(resource => (
      <div  key={resource.resource_id}
     
      style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
          {/* Added a key for list rendering */}
        <input
          type="checkbox"
          checked={selectedResources.has(resource.resource_id)}
          onChange={() => toggleResourceSelection(resource.resource_id)}
          style={{ marginRight: '10px' }}
        />
        <h3>{resource.display_name || resource.resource_name}</h3>
      </div>
    ))
  ) : (
    <p>No Data</p>
  )}
</div>

        </div>
      )}

      <Button color="primary" onClick={addSelectedResources} style={{ marginTop: '20px' }}>
        Add Selected Resources
      </Button>
    </div>
  );
};

export default SearchComponent;