import React, { useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Button, Input, FormGroup, Label, Row, Col, Collapse , Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classnames from 'classnames';
import { signedURL, uploadResourse, addResource, addAssignment,removeResource, addTagsResoures } from '../../../../Api/lms/apiUtils'; 
import Search from './search';
import { H5, H4 } from '../../../../AbstractElements';
import { toast } from 'react-toastify';
import { Trash } from 'react-feather';
import './style.scss';
import { Video, Music, FileText , } from 'react-feather';
import { MdOutlineCancel } from "react-icons/md";
import { tagsMap } from './tags';
import Select from "react-select";


interface Resource {
  type: any;
  name: any;
  file: File;
}

interface Assignment {
  name: any;
  instructions: any;
  resources: Resource[];
}

interface AddTopicCardProps {
  savedTopic: any;
  onSaveTopic: ( topicNumber: any, topicName: any, details: any, resources: Resource[], isExtended: boolean, artiumGrade: boolean, trinityGrade: boolean) => void;
  onSaveAssignment: (assignments: Assignment[]) => void;
  toggle: () => void;
}

const AddTopicCard: React.FC<AddTopicCardProps> = ({
  savedTopic,
  onSaveTopic,
  onSaveAssignment,
  toggle
}) => {
  const [topicName, setTopicName] = useState<any>('');
  const [topicNumber, setTopicNumber] = useState<any>('');
  const [details, setDetails] = useState<any>('');
  const [extendToFutureLessons, setExtendToFutureLessons] = useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [resourceType, setResourceType] = useState<any>();
  const [resourceFiles, setResourceFiles] = useState<FileList | null>(null);
  const [displayName, setDisplayName] = useState<any>('');
  const [editorContent, setEditorContent] = useState<any>('');
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [showAssignmentSection, setShowAssignmentSection] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [resourceToDelete, setResourceToDelete] = useState<Resource | null>(null);
  const [newAssignmentName, setNewAssignmentName] = useState<any>('');
  const [newAssignmentInstruction, setNewAssignmentInstruction] = useState<any>('');
  const [assignmentResources, setAssignmentResources] = useState<Resource[]>([]);
  const [isTopicSaved, setIsTopicSaved] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false); // State to handle collapse
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [isResourceSaved, setResourceSaved] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [isUploading, setIsUploading] = useState(false); 
  const [selectedTags, setSelectedTags] = useState<number[]>([]);


  // Additional states for checkboxes
  const [isExtended, setIsExtended] = useState<boolean>(false); 
  const [artiumGrade, setArtiumGrade] = useState<boolean>(false);
  const [trinityGrade, setTrinityGrade] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenExisting, setIsOpenExsiting] = useState(false);

  const toggleFileModal = () => setFileModalOpen(!isFileModalOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleExistingCollapse = () => setIsOpen(!isOpen);

  const toggleCollapse = () => setIsOpen(!isOpen); // Toggle function for collapse
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { tagsList, isLoading: tagsLoading } = tagsMap()
  const tagOptions = tagsList.map((tag) => ({ value: tag.id, label: tag.name }));

  const clearFileInput = () => {
setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // This resets the input field.
    }
  };
  
  const handleAddResources = async () => {
    setIsUploading(true);
  
    if (resourceFiles) {
      const newResources = Array.from(resourceFiles).map((file) => ({
        type: file.name.split('.').pop(),
        name: (displayName || file.name).split(' ').join('_'),
        file: file,
      }));
  
      try {
        // Loop through each file and upload
        for (let i = 0; i < newResources.length; i++) {
          const resource = newResources[i];
          const fileExtension = resource.file.name.split('.').pop();
  
          const response = await signedURL({ fileName: resource.name, fileType: fileExtension });
          if (response.status === 200) {
            const resourceName = response.data.fileName; // Use a local variable
            const { signedUrl } = response.data;
  
            const uploadResponse = await uploadResourse(signedUrl, resource.file);
            if (uploadResponse.status === 200) {
              const resourceResponse = await addResource({
                resourceName: resourceName,
                displayName: displayName || resource.name,
                type: fileExtension,
                description: ' ',
                url: ' ',
  
              });
  
              if (resourceResponse.status === 200) {
                const newResource = {
                  ...resource,
                  resource_name: resourceName, // Add to the resource object
                  resource_id: resourceResponse.data.resource[0].resource_id, // Add to the resource object
                };
  
                // Call addTagsResoures API here
                if (selectedTags.length > 0) {
                  const tagResponse = await addTagsResoures({
                    tagIds: selectedTags,
                    resourceId: newResource.resource_id,
                  });
  
                  if (tagResponse) {
                    toast.success(`Tags assigned to ${resource.name} successfully`);
            
                  } else {
                    console.error('Error assigning tags', tagResponse);
                    toast.error(`Failed to assign tags to ${resource.name}`);
                  }
                }
  
                setResources((prevResources) => [...prevResources, newResource]);
                toast.success(`File ${resource.name} uploaded successfully`);
              } else {
                toast.error('Error adding resource');
              }
            } else {
              console.error('Error uploading file', uploadResponse);
              toast.error('Error uploading file');
            }
          }
        }
  
        // Clear input and reset state after all files are uploaded
        clearFileInput();
        setResourceFiles(null);
        setSelectedTags([]);
        setDisplayName('');
      } catch (error) {
        console.error('Error during file upload process', error);
        toast.error('Error during file upload process');
      } finally {
        setIsUploading(false);
      }
    } else {
      toast.error('Please select files to upload.');
      setIsUploading(false);
    }
  };
  
  


  const openDeleteModal = (resource: Resource) => {
    setResourceToDelete(resource);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setResourceToDelete(null);
  };


  const deleteResource = async () => {
    if (resourceToDelete && savedTopic) {
      try {
        const response = await removeResource((resourceToDelete as any).resource_id);
        if (response.status === 200) {
          toast.success('Resource removed successfully');
          const updatedResources = resources.filter(
            (res: Resource) => res !== resourceToDelete
          );
          setResources(updatedResources); // Update the state to reflect the removed resource
          closeDeleteModal(); // Close the modal after successful deletion
        } else {
          toast.error('Failed to remove resource:', response.data.message);
        }
      } catch (error) {
        console.error('Error removing resource:', error);
        toast.error('Unable to delete the resource. Save the topic first, then try deleting the resource');
      }
    }
  };
  // Save the topic and resources
  const handleSaveTopic = () => {
    if (topicName) {
      onSaveTopic( topicNumber, topicName, editorContent, resources, isExtended, artiumGrade, trinityGrade);
  
    } else {
      toast.error('Topic name is required');
    }
  };

  // Add a new assignment
  const handleAddAssignment = async() => {
    if (newAssignmentName && newAssignmentInstruction) {
      const newAssignment: Assignment = {
        name: newAssignmentName,
        instructions: newAssignmentInstruction,
        resources: assignmentResources,
      };
      if (savedTopic) {
        let reqParam = {
          "topicId": (savedTopic as any)?.topic_id,
          "name": newAssignment.name,
          "description": newAssignment.instructions,
          "resources": newAssignment.resources.map((resource: any) => ( resource.resource_id))
        };
  
        // const updatedAssignments = [...(selectedTopic.resources || []), ...assignments.resources];
        // setSelectedTopic({
        //   ...selectedTopic,
        //   resources: updatedAssignments,
        // });
  
        try {
          const response = await addAssignment(reqParam);
          if (response.status === 201) {
            setAssignments([...assignments, newAssignment]);
            setNewAssignmentName('');
            setNewAssignmentInstruction('');
            setAssignmentResources([]);
           toast.success('Assignment added successfully');
           
          } else {
            console.error('Failed to add assignment:', response.data.message);
          }
        } catch (error) {
          console.error('Error adding assignment:', error);
        }
      }
    } else {
      alert('Assignment name and instructions are required');
    }
  };

  // Save assignments after topic is saved
  const handleSaveAssignments = () => {
    if (assignments.length > 0) {
      onSaveAssignment(assignments);
    } else {
      alert('Please add at least one assignment.');
    }
  };

  // Handle assignment resource selection
  const handleAssignmentResourceSelection = (resource: Resource, isChecked: boolean) => {
    if (isChecked) {
      setAssignmentResources([...assignmentResources, resource]);
    } else {
      setAssignmentResources(assignmentResources.filter((r) => r.name !== resource.name));
    }
  };

  return (
    <Card>
   <CardHeader>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <h2 style={{ color: "#e82c86" }}>Add Topic</h2>
    <Button style={{ color: "#e82c86" }} onClick={toggle}>
      <MdOutlineCancel size={22} />
    </Button>
  </div>
</CardHeader>

      <CardBody style={{ maxHeight: '60vh', overflowY: 'auto', marginTop: '10px' }}>
      <FormGroup>
  <Label for="topicNumber">
 <b>   Topic Number: </b><span style={{ color: 'red' }}>*</span>
  </Label>
  <Input
    type="number"
    id="topicNumber"
    required
    value={topicNumber}
    onChange={(e) => setTopicNumber(e.target.value)}
    disabled={isTopicSaved}
  />
</FormGroup>


     {/* Topic Name */}
        <FormGroup>
  <Label for="topicName">
 <b>   Topic Name: </b><span style={{ color: 'red' }}>*</span>
  </Label>
  <Input
    type="text"
    id="topicName"
    required
    value={topicName}
    onChange={(e) => setTopicName(e.target.value)}
    disabled={isTopicSaved}
  />
</FormGroup>


        {/* Topic Details */}
        <FormGroup>
          <Label for="details"><b>Details:</b> </Label>
          <ReactQuill value={editorContent} onChange={setEditorContent} />
        </FormGroup>

        {/* Additional Checkboxes */}
        <FormGroup>
  <Row form>
    <Col md={4}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Label style={{ marginRight: '10px' }}><b>Is this an extended:</b></Label>
        <Input
          type="radio"
          name="isExtended"
          className="levelChk"
          value="yes"
          checked={isExtended === true}
          onChange={() => setIsExtended(true)}
        />
        <Label style={{ marginLeft: '5px', marginRight: '15px' }}>Yes</Label>
        <Input
          type="radio"
          name="isExtended"
          value="no"
          className="levelChk"
          checked={isExtended === false}
          onChange={() => setIsExtended(false)}
        />
        <Label style={{ marginLeft: '5px' }}>No</Label>
      </div>
    </Col>
  </Row>
</FormGroup>

{resources.length > 0 && (
  <h4 style={{ marginTop: '10px' }}>Resource List</h4>
)}

<ul style={{ marginTop: '10px' }}>
  {resources.map((resource, index) => (
    <li key={index}>
      <Card>
        <CardBody>
          <div style={{ display: "flex", justifyContent: "space-between" }}> 
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              {resource.type === 'video' && <Video color="grey" />}
              {resource.type === 'audio' && <Music color="grey" />}
              {resource.type === 'article' && <FileText color="grey" />}
              {resource.type === 'pdf' && <FileText color="grey" />}
              {resource.type === 'mp4' && <Video color="grey" />}
              {resource.type === 'mp3' && <Music color="grey" />}

              <span style={{ marginLeft: "10px" }}>
                {resource.name || (resource as any).display_name || (resource as any).resource_name}
              </span>


            </div>
            
            <Trash onClick={() => openDeleteModal(resource)} size={18} />
          </div>       
        </CardBody>
      </Card>
    </li>
  ))}
</ul>


<Card>




<div >


<div >
      {/* Button to open modal */}
 

      {/* Modal structure */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Existing Resources</ModalHeader>
        <ModalBody>
          {/* Card component inside the modal */}
   
             


       
              <Search setResources={setResources} />

  
        </ModalBody>
      </Modal>
 


    
      <div style={{ padding: '20px' }}>
  {/* File and Display Name in One Line */}
  <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '20px' }}>
    {/* File Upload */}
    <FormGroup style={{ flex: '1 1 50%' }}>
      <Label for="fileUpload" style={{ fontWeight: 'bold' }}>
        Choose File
      </Label>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setResourceFiles(e.target.files);
            const firstFileName = e.target.files[0].name.split('.')[0]; // Extract file name without extension
            setDisplayName(firstFileName);
          }
        }}
        multiple
        accept="video/*,audio/*,application/pdf,image/png,image/jpeg"
        className="form-control"
      />
    </FormGroup>

    {/* Display Name */}
    <FormGroup style={{ flex: '1 1 50%' }}>
      <Label for="displayName" style={{ fontWeight: 'bold' }}>
        Display Name
      </Label>
      <Input
        type="text"
        placeholder="Display Name (optional)"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      />
    </FormGroup>
  </div>

{/* 
  <FormGroup>
    <Label style={{ fontWeight: 'bold' }}>Select Tags</Label>
    <Select
      isMulti

      options={tagOptions} // Tag options provided from `tagsMap`
      onChange={(selectedOptions) => {
        setSelectedTags(selectedOptions.map((option) => option.value));
      }}
      placeholder="Choose tags for resources"
    />
  </FormGroup> */}

  {/* Save Button */}
  <div style={{ marginTop: '20px', textAlign: 'right' }}>
    {!isTopicSaved && (
      <Button
        color="primary"
        onClick={() => {
          if (resourceFiles && resourceFiles.length > 0) {
            handleAddResources();
          } else {
            toast.error('Please select files to upload.');
          }
        }}
        disabled={isUploading}
      >
        {isUploading ? <Spinner size="sm" /> : 'Add Resources'}
      </Button>
    )}
  </div>
</div>


              <div style={{display:"flex", justifyContent:"center",}} >    <H5  >Or</H5></div>
              <div style={{display:"flex", justifyContent:"center", marginTop:"10px"}} >
              <Button 
    color="secondary"
    className={classnames('custom-cancel-button')} onClick={toggleModal}>
        Add Existing Resource
      </Button>
      </div>
</div>
    </div></Card>


       


   
      {/* Delete confirmation modal */}
      <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
        <ModalHeader toggle={closeDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure you want to delete this resource?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={deleteResource}>
            Delete
          </Button>
          <Button className="custom-cancel-button" onClick={closeDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
       
          {!isTopicSaved && (
            <Button color="primary" onClick={handleSaveTopic}>
              Save Topic
            </Button>
          )}
      
    

        {isTopicSaved && (
          <>
            {/* Assignment Section */}
            <FormGroup style={{ marginTop: '20px' }}>
              <Label>Assignments:</Label>
              <Button
                color="info"
                onClick={() => setShowAssignmentSection(!showAssignmentSection)}
                style={{ marginLeft: '10px' }}
              >
                {showAssignmentSection ? 'Hide' : 'Add Assignment'}
              </Button>
            </FormGroup>

            {showAssignmentSection && (
              <>
                <FormGroup>
                  <Label for="assignmentName">Assignment Name:</Label>
                  <Input
                    type="text"
                    id="assignmentName"
                    value={newAssignmentName}
                    onChange={(e) => setNewAssignmentName(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="assignmentInstructions">Instructions:</Label>
                  <ReactQuill value={newAssignmentInstruction} onChange={setNewAssignmentInstruction} />
                </FormGroup>

                {/* Assignment Resources */}
                <FormGroup>
                  <Label>Assignment Resources:</Label>
                  {resources.map((resource, index) => (
                    <div key={index}>
                      <Input
                        type="checkbox"
                        className='levelChk'
                        onChange={(e) => handleAssignmentResourceSelection(resource, e.target.checked)}
                      />
                      <Label>{resource.name || (resource as any).display_name ||(resource as any).resource_name }</Label>
                    </div>
                  ))}
                </FormGroup>

                <Button color="primary" onClick={handleAddAssignment}>
                  Add Assignment +
                </Button>
              </>
            )}

            <ul>
              {assignments.map((assignment, index) => (
                <li key={index}>
                  {assignment.name} - {assignment.instructions}
                  <ul>
                    {assignment.resources.map((resource, resIndex) => (
                      <li key={resIndex}>
                        {resource.type.toUpperCase()}: {resource.name || (resource as any).display_name ||(resource as any).resource_name }
                      </li>
                    ))}
                  </ul>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  {/* <Button color="success" onClick={handleSaveAssignments} >
                    Save Assignments
                  </Button> */}
                  <Button color="primary" onClick={toggle} style={{ marginTop: '20px' }}>
                    Cancel
                  </Button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default AddTopicCard;
