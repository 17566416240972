import React from 'react';
import { Provider } from 'react-redux';
import RouterData from './Routes';
import { ToastContainer } from 'react-toastify';
import Store from './ReduxToolkit/Store';
import axios from 'axios';
import { ConfigProvider } from './Api/global/ApiContext'; 

// Axios interceptors
axios.interceptors.request.use(
  request => {
    const token = localStorage.getItem('authToken');
    console.log(token, 'token');
    if (!(request as any).skipAuth && token) {
      request.headers['Authorization'] = typeof window !== 'undefined' ? token : null;
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => res,
  err => {
    if (err?.response?.status === 401) {
      // Handle unauthorized error
      // window.open(window.location.origin + '/', "_self");
    } else {
      if (err?.response?.status) {
        // Handle other API errors
        // Mixpanel.track(MIX_PANEL_CONST.API_ERROR+err?.response?.status, { error: err?.response?.data});
      }
    }
    return Promise.reject(err);
  }
);

const App = () => {
  return (
    <>
      <Provider store={Store}>
        <ConfigProvider> {/* Wrap your app with ConfigProvider */}
          <RouterData />
          <ToastContainer />
        </ConfigProvider>
      </Provider>
    </>
  );
};

export default App;
