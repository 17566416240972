

import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, P } from "../../AbstractElements";
import axios from 'axios';
import { EmailJobPlaceholder } from '../../utils/Constant';

import { toast } from "react-toastify";
import { FaCheck } from 'react-icons/fa';
import { WEB_API_BASEURL, UTILITY_BASEURL } from '../../Api/api-constant';
import { CreateAccount, DoNotAccount, EmailAddress, ForgotPassword, HoverableRowsWithHorizontalBorders, Href, Otp, RememberPassword, SignIn, SignInAccount, SignInWith } from "../../utils/Constant";

const Login: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOTPSent] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Get Login OTP');
  const [verificationStatus, setVerificationStatus] = useState<string>('');
  const [showCheckIcon, setShowCheckIcon] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleGetLoginOTP = () => {
    if (!email) {
      toast.error('Please Enter valid EmailId/mobile number.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    if (!email || (!emailRegex.test(email) && !mobileRegex.test(email))) {
      toast.error('Please enter a valid email or mobile number.');
      return;
    }
    setButtonText('Sending...');
    setButtonDisabled(true);
    let validateUrl = '';
    let sendOtpUrl = '/users/user/sendloginotp?';

    if (emailRegex.test(email)) {
      validateUrl = `/users/ValidateNewUser1/${encodeURIComponent(email)}`;
      sendOtpUrl += `emailId=${encodeURIComponent(email)}`;
    } else if (mobileRegex.test(email)) {
      validateUrl = `/users/ValidateNewUser/+91/${encodeURIComponent(email)}`;
      sendOtpUrl += `mobileNo=${encodeURIComponent(email)}`;
    } else {
      return;
    }

    axios.get(`${WEB_API_BASEURL}${validateUrl}`)
      .then((response) => {
        if (response.status === 200) {
          setVerificationStatus('Verified successfully');
          setShowCheckIcon(true);
          setTimeout(() => setShowCheckIcon(false), 3000);

          if (response.data === "EmailId not Registered") {
            toast.error('Email is not registered.');
            setOTPSent(false);
            setButtonText('Get Login OTP');
            setButtonDisabled(false);
            return;
          }
          if (response.data === "Mobile number not registered") {
            toast.error('Mobile Number is not registered.');
            setOTPSent(false);
            setButtonText('Get Login OTP');
            setButtonDisabled(false);
            return;
          }

          axios.post(`${WEB_API_BASEURL}${sendOtpUrl}`)
            .then((otpResponse) => {
              if (otpResponse.status === 200) {
                toast.success('OTP sent successfully.');
                setOTPSent(true);
                setButtonText('Get Login OTP');
              } else {
                toast.error('Error while sending OTP');
                setButtonText('Get Login OTP');
              }
              setButtonDisabled(false);
            })
            .catch((otpError) => {
              toast.error('Not a valid user');
              setButtonText('Get Login OTP');
              setButtonDisabled(false);
            });
        }
      })
      .catch((error) => {
        toast.error('API Error: Something went wrong during validation');
        setButtonText('Get Login OTP');
        setButtonDisabled(false);
      });
  };

  const handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!otpSent) {
      return;
    }
    setLoading(true);
    axios.post(`${WEB_API_BASEURL}/auth/user/otplogin`, {
      code: "+91",
      emailId: email.includes('@') ? email : null,
      mobileNo: !email.includes('@') ? email : null,
      otp,
    })
      .then((response) => {
        if (response.status === 200) {
          
            const token = response.headers['authorization'];
            if (token) {
              localStorage.setItem('authToken', token);
            }
          const payload = {
            uId: response.data.uId,
            role: 'admin'
          };
          axios.post(`${UTILITY_BASEURL}/api/authentication/credentials`, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((credentialResponse) => {
              setLoading(false);
              if (credentialResponse.status === 200) {
                toast.success('Logged in successfully.');
                localStorage.setItem("login", JSON.stringify(true));
                localStorage.setItem("credentialId", response.data.credentialId);
                localStorage.setItem("userId",response.data.userId)
                navigate('/pages/CourseList');
              } else {
                toast.error("Invalid OTP");
              }
            })
            .catch((error) => {
              toast.error('API Error: Something went wrong during login');
              setLoading(false);
            });
        } else {
          toast.error("Invalid OTP");
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error('API Error: Something went wrong during OTP verification');
        setLoading(false);
      });
  };

  const handleResendOTP = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    let sendOtpUrl = '/users/user/sendloginotp?';
    if (emailRegex.test(email)) {
      sendOtpUrl += `emailId=${encodeURIComponent(email)}`;
    } else if (mobileRegex.test(email)) {
      sendOtpUrl += `mobileNo=${encodeURIComponent(email)}`;
    } else {
      return;
    }
    axios.post(`${WEB_API_BASEURL}${sendOtpUrl}`)
      .then((otpResponse) => {
        if (otpResponse.status === 200) {
          toast.success('OTP resent successfully.');
        } else {
          toast.error('Error while resending OTP');
        }
      })
      .catch((otpError) => {
        toast.error('API Error: Something went wrong while resending OTP');
      });
  };

  const handleBack = () => {
    setOTPSent(false);
    setEmail("");
    setOtp("");
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  <img className="img-fluid for-light" src="https://admin.artiumacademy.com/static/media/ArtiumAcademyLogo.35b738e5eb795e4f1c7366ee86022780.svg" alt="loginpage" />
                </Link>
              </div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={handleLoginSubmit}>
                  <H3>{SignInAccount}</H3>
                  <P>{"Enter your email & OTP"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input 
                        type="email" 
                        placeholder={EmailJobPlaceholder}
                        required  
                        value={email} 
                        onChange={(event) => setEmail(event.target.value)} 
                        disabled={otpSent}
                      />
                      {showCheckIcon && <FaCheck style={{ color: 'green', marginLeft: '8px' }} title={verificationStatus} />}
                    </div>
                  </FormGroup>
                  {otpSent && (
                    <FormGroup>
                      <Label className="col-form-label">{Otp}</Label>
                      <div className="form-input position-relative">
                        <Input 
                          type={show ? "text" : "password"} 
                          placeholder="****" 
                          maxLength={4} 
                          pattern="\d{4}"
                          onChange={(event) => setOtp(event.target.value)} 
                          value={otp} 
                        />
                        <div className="show-hide" onClick={() => setShow(!show)}>
                          <span className="show"> </span>
                        </div>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup className="mb-0 form-sub-title">
                    {otpSent ? (
                      <>
                        <span onClick={handleResendOTP} style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Resend OTP</span>
                        <Btn color="primary" block className="w-100 mt-3" type="submit" disabled={loading}>
                          {SignIn}
                        </Btn>
                      </>
                    ) : (
                      <Btn block className="w-100 mt-3" color="primary" 
                      // onClick={!buttonDisabled ? handleGetLoginOTP : null}>
                      onClick={handleGetLoginOTP}>
                        {buttonText}
                      </Btn>
                    )}
                  </FormGroup>
                  {otpSent && <Btn color="primary" block className="w-100" onClick={handleBack}>Back</Btn>}
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;