import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Video, Music, FileText, Plus, Edit, Eye } from 'react-feather';
import { searchResources, addTagsResoures, editResourceTags, resourceByTags } from '../../../../Api/lms/apiUtils';
import { _addResource } from '../../../../Api/lms/utility';
import PreviewComponent from './PreviewComponent';
import { toast } from "react-toastify";
import {tagsMap } from './tags';
import Select from "react-select";
import { CiEdit } from "react-icons/ci";
import classnames from 'classnames';

interface Resource {
  resource_id: number;
  resource_name: string;
  type: string;
  description: string;
  url: string;
  created_at: string;
  updated_at: string;
  display_name: string;
  fileUrl: string;
  tags: any;
}

function ResourcesList() {
  const [resources, setResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [previewResource, setPreviewResource] = useState<Resource | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileInputs, setFileInputs] = useState<{ file: File; displayName: string }[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [AddSelectedTags, setAddSelectedTags] = useState<any[]>([]);


  const [isEditTagsModalOpen, setIsEditTagsModalOpen] = useState(false);
  const [currentEditResource, setCurrentEditResource] = useState<Resource | null>(null);
  const [searchSelectedTags, setSearchSelectedTags] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('1');

  const lastResourceRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { tagsList, isLoading: tagsLoading } = tagsMap()
  const tagOptions = tagsList.map((tag) => ({ value: tag.id, label: tag.name }));

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setResources([]);
      setPage(1);
      setHasMore(true);
      fetchResources(1);
    }
  };

  const fetchResources = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const response = await searchResources(searchQuery, pageNumber, 20);
      const newResources = response.results || [];
      if (newResources.length === 0) {
        setHasMore(false);
      } else {
        setResources((prevResources) => [...prevResources, ...newResources]);
      }
    } catch (error) {
      console.error('Error fetching resources:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchResourcesByTags = async () => {
    setIsLoading(true);
    try {
      const response = await resourceByTags({
        tagIds: searchSelectedTags,
        limit: 20,
        offset: (page - 1) * 20,
      });
      const newResources = response.data.resources || [];
      if (newResources.length === 0) {
        setHasMore(false);
      } else {
        setResources((prevResources) => [...prevResources, ...newResources]);
      }
    } catch (error) {
      console.error('Error fetching resources by tags:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setResources([]);
    setPage(1);
    setHasMore(true);
    if (activeTab === '1') {
      fetchResources(1);
    } else {
      fetchResourcesByTags();
    }
  }, [searchQuery, searchSelectedTags, activeTab]);

  useEffect(() => {
    if (page > 1) {
      if (activeTab === '1') {
        fetchResources(page);
      } else {
        fetchResourcesByTags();
      }
    }
  }, [page]);

  const handlePreviewToggle = (resource: Resource | null) => {
    setPreviewResource(resource);
    setIsPreviewOpen(!isPreviewOpen);
  };

  const handleScroll = () => {
    if (
      lastResourceRef.current &&
      lastResourceRef.current.getBoundingClientRect().bottom <= window.innerHeight + 100 &&
      !isLoading &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const inputs = selectedFiles.map(file => ({
        file,
        displayName: file.name.split('.')[0]
      }));
      setFileInputs(inputs);
    }
  };
  const handleDisplayNameChange = (index: number, newDisplayName: string) => {
    setFileInputs(prevInputs => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].displayName = newDisplayName;
      return updatedInputs;
    });
  };


  const handleEditTags = (resource: Resource) => {
    setCurrentEditResource(resource);
    const mappedTags = resource.tags
      .map((tagName: string) =>
        tagsList.find((tag : any) => tag.name === tagName)
      )
      .filter((tag: any) => tag)
      .map((tag: any) => ({ value: tag!.id, label: tag!.name }));
    setSelectedTags(mappedTags);
    setIsEditTagsModalOpen(true);
  };

  const handleSaveTags = async () => {
    if (!currentEditResource) return;
    try {
      const tagIds = selectedTags.map((tag) => tag.value);
      const data = {
        tagIds,
        resourceId: currentEditResource.resource_id,
      };
      const response = await editResourceTags(data);
      if (response.success) {
        toast.success('Tags updated successfully!');
        setIsEditTagsModalOpen(false);
        fetchResources(1);
        const updatedResources = resources.map((res) =>
          res.resource_id === currentEditResource.resource_id
            ? { ...res, tags: selectedTags.map((tag) => tag.label) }
            : res
        );
        setResources(updatedResources);
      } else {
  toast.success('Tags updated successfully!');    
        const updatedResources = resources.map((res) =>
          res.resource_id === currentEditResource.resource_id
            ? { ...res, tags: selectedTags.map((tag) => tag.label) }
            : res
        );
        fetchResources(1);
        setIsEditTagsModalOpen(false);
        setResources(updatedResources);
      }
    } catch (error) {
      console.error('Error updating tags:', error);
      toast.error('Error updating tags. Please try again.');
    }
  };
  const handleSave = async () => {
    if (fileInputs.length > 0 && !isSaving) {
      setIsSaving(true);
      setIsLoading(true);
      try {
        for (let i = 0; i < fileInputs.length; i++) {
          const { file, displayName } = fileInputs[i];

          // Step 1: Add resource
          const resourceRes = await _addResource([file], displayName);
          if (!resourceRes || !resourceRes.resource_id) {
            toast.error(`Failed to add resource "${file.name}"`);
            continue;
          }
          toast.success(`Resource "${file.name}" added successfully`);

          // Step 2: Add tags to the resource
          if (AddSelectedTags.length > 0) {
            const tagRes = await addTagsResoures({
              tagIds: AddSelectedTags,
              resourceId: resourceRes.resource_id,
            });
            if (tagRes) {
              // toast.success(`Tags assigned to resource "${file.name}" successfully`);
            } else {
              // toast.error(`Failed to assign tags to resource "${file.name}"`);
            }
          }
        }

        // Reset states after saving
        setFileInputs([]);
        setSelectedTags([]);
        setResources([]);
        setPage(1);
        fetchResources(1);
      } catch (error) {
        console.error('Error saving resources:', error);
        toast.error('Error saving resources');
      } finally {
        setIsSaving(false);
        setIsLoading(false);
        setIsModalOpen(false);
      }
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      setFileInputs([]); 
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; 
      }
    }
  };
  

  // const toggleModal = () => {
  //   setIsModalOpen(!isModalOpen);
  //   if (!isModalOpen) {
  //     setFileInputs([]);
  //     if (fileInputRef.current) {
  //       fileInputRef.current.value = '';
  //     }
  //   }
  // };

  return (
    <div>
      <Nav tabs>
  <NavItem>
    <NavLink
      className={classnames({ active: activeTab === '1' })}
      onClick={() => toggleTab('1')}
    >
      Search by Name
    </NavLink>
  </NavItem>
  <NavItem>
    <NavLink
      className={classnames({ active: activeTab === '2' })}
      onClick={() => toggleTab('2')}
    >
      Search by Tag
    </NavLink>
  </NavItem>
</Nav>

{/* Button is placed outside TabContent */}
<div style={{ marginTop: '10px', textAlign: 'left' }}>
  <Button color="primary" onClick={toggleModal}>
    Add Resource
  </Button>
</div>

<TabContent activeTab={activeTab}>
  <TabPane tabId="1">
    <FormGroup style={{ marginTop: '10px' }}>
      <Input
        type="text"
        placeholder="Search resource Name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </FormGroup>
  </TabPane>
  <TabPane tabId="2">
    <div style={{ marginTop: '10px' }}>
      <Select
        isMulti
        options={tagOptions}
        onChange={(selectedOptions) => {
          const tags = selectedOptions.map(option => option.value);
          setSearchSelectedTags(tags);
        }}
        placeholder="Search by tags..."
      />
    </div>
  </TabPane>
</TabContent>


      {isLoading && resources.length === 0 ? (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
            <p>Loading resources...</p>
          </Col>
        </Row>
      ) : (
        <Row className="user-cards-items user-card-wrapper">
          {resources.length > 0 ? (
            resources.map((resource, index) => (
              <Col
                key={resource.resource_id}
                xl="4"
                sm="6"
                xxl="3"
                className="col-ed-4 box-col-4"
              >
                <div ref={index === resources.length - 1 ? lastResourceRef : null}>
                  <Card style={{ maxHeight: '80vh', height: '200px', overflowY: 'hidden', marginTop: '20px', position: 'relative' }}>
                    <CardBody style={{ position: 'relative' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '-14px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          {resource.type === 'video' && <Video color="grey" />}
                          {resource.type === 'audio' && <Music color="grey" />}
                          {resource.type === 'pdf' && <FileText color="grey" />}
                          {resource.type === 'mp3' && <Music color="grey" />}
                          {resource.type === 'mp4' && <Video color="grey" />}
                          {resource.type === 'mov' && <Video color="grey" />}
                          <h6 style={{ marginLeft: '10px' }}>
                            {resource.display_name ? resource.display_name.split('.')[0] : resource.resource_name}
                          </h6>
                        </div>
                        <div>
                          <CiEdit size={18} style={{ color: "#e82c86" }} onClick={() => handleEditTags(resource)} />
                          <Eye size={18} style={{ color: "#e82c86" }} onClick={() => handlePreviewToggle(resource)} />
                        </div>
                      </div>
                      <div style={{ marginTop: '10px', marginLeft: '-14px' }}>
                        {Array.isArray(resource.tags) && resource.tags.filter(tag => tag).length > 0 ? (
                          <div>
                            <strong>Tags:</strong>
                            <div style={{ marginLeft: '5px', display: 'flex', flexWrap: 'wrap', gap: '5px', maxHeight: '80px', overflowY: 'auto' }}>
                              {resource.tags.filter(tag => tag).map((tag, index) => (
                                <span key={index} style={{ backgroundColor: '#f1f1f1', borderRadius: '12px', padding: '4px 10px', fontSize: '12px', color: '#333' }}>
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div style={{ fontStyle: 'italic', color: '#888' }}>No tags available</div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))
          ) : (
            <Col xs="12" className="text-center">
              <p>No resources found</p>
            </Col>
          )}
        </Row>
      )}

      {isLoading && resources.length > 0 && (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
          </Col>
        </Row>
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Resource</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label htmlFor="fileUpload" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Upload Files
            </label>
            <input
              type="file"
              accept="video/*,audio/*,application/pdf,image/png,image/jpeg"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              className="form-control"
              id="fileUpload"
            />
          </FormGroup>

          {fileInputs.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              {fileInputs.map((input, index) => (
                <div key={index} style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f9f9f9' }}>
                  <div style={{ width: '100%' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{input.file.name}</p>
                    <Input
                      type="text"
                      value={input.displayName}
                      onChange={(e) => handleDisplayNameChange(index, e.target.value)}
                      placeholder="Enter Display Name"
                      style={{ marginTop: '5px', width: '100%' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          <FormGroup style={{ marginTop: '20px' }}>
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>Select Tags</label>
            <Select
              isMulti
              options={tagOptions}
              onChange={(selectedOptions) => {
                setAddSelectedTags(selectedOptions.map((option) => option.value));
              }}
              placeholder="Choose tags for resources"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? <Spinner size="sm" /> : 'Save'}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isEditTagsModalOpen} toggle={() => setIsEditTagsModalOpen(false)}>
        <ModalHeader toggle={() => setIsEditTagsModalOpen(false)}>Edit Tags</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label style={{ fontWeight: 'bold' }}>Select Tags</label>
            <Select
              isMulti
              options={tagOptions}
              value={selectedTags}
              onChange={(options) => setSelectedTags(Array.isArray(options) ? [...options] : [])}
              placeholder="Choose tags for the resource"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveTags}>
            Save
          </Button>
          <Button color="secondary" onClick={() => setIsEditTagsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {previewResource && (
        <PreviewComponent
          resource={previewResource}
          isOpen={isPreviewOpen}
          onClose={() => setPreviewResource(null)}
        />
      )}
    </div>
  );
}

export default ResourcesList;
