import StudentList from '../../../Componant/Dashboard/Student/StudentList'


const StudentProfile = () => {
    
  return (
    <div className='page-body'>
      <StudentList />
    </div>
  )
}

export default StudentProfile