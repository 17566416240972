import { MenuItem } from "../../Types/Layout/SidebarType";


export const MenuList: MenuItem[] = [
  {
    title: "General",
    lanClass: "lan-1",
    Items: [
      {
        title: "User Profile",
        icon: "home",
        type: "sub",
        lanClass: "lan-3",
        children: [
          {
            title:"Teacher",
            // path:`${process.env.PUBLIC_URL}/pages/teacherProfile`,
            type:"link"
          },
          {
            title:"Student",
            // path:`${process.env.PUBLIC_URL}/pages/studentProfile`,
            type:"link"
          }
        ]
      },
      {
        title: "Teacher Operation",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Teacher Compensation",
            // path:`${process.env.PUBLIC_URL}/pages/teacher-compensation`,
            type:"link"
          },
          {
            title:"Teacher Capacity Forecast",
            // path:`${process.env.PUBLIC_URL}/pages/teacherForcast`,
            type:"link"
          },
          {
            title:"Leave Request",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Weekly Engagement",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          }
        ]
      },
      {
        title: "Operations",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Class Report",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Addon Report",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Paid Slot Details",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Certificates",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"QA Policy",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          }
        ]
      },
      {
        title: "AE Reports",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Airline Slots",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Demo Status",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Category Wise AE Slots",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"AE Conversion",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          
        ]
      },
      {
        title: "Finance",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Referrals",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Refunds",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          }         
        ]
      },
      {
        title: "Online Group Management",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Create Group",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },        
        ]
      },
      {
        title: "Offline Group Management",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Create Group",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          {
            title:"Students",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          }         
        ]
      },
      {
        title: "Marketing",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"AHA Moment",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },        
        ]
      },
      {
        title: "Academics",
        // icon: "support-tickets",
        type: "sub",
        children: [
          {
            title:"Artium Library",
            // path:`https://support.pixelstrap.com/`,
            type:"link"
          },
          
          {
            title:"LMS",
            path:`${process.env.PUBLIC_URL}/pages/CourseList`,
            type:"link"
          },
        ]
      },
      
          {
            title:"Webinar",
            lanClass: "lan-3",
            // path:`${process.env.PUBLIC_URL}/pages/teacherProfile`,
            type:"link"
          },
          {
            title:"Demo Operation",
            lanClass: "lan-3",
            // path:`${process.env.PUBLIC_URL}/pages/studentProfile`,
            type:"link"
          }
        ]
      },
    ]
  