import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { H4 } from '../../../../AbstractElements';
import { Worker, Viewer } from '@react-pdf-viewer/core';  // PDF viewer
import '@react-pdf-viewer/core/lib/styles/index.css';  // PDF viewer styles

interface Resource {
  resource_id: number;
  resource_name: string;
  type: any;
  url: any;
  display_name: any;
}

interface PreviewComponentProps {
  resource: Resource;
  isOpen: boolean;
  onClose: () => void;
}

const extensionConfig = {
  video: ['mp4', 'mkv', 'avi', 'mov', 'wmv', 'flv', 'webm', 'm4v', '3gp', 'mpeg'],
  audio: ['mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'aiff', 'alac', 'opus'],
  article: ['article'],
  pdf: ['pdf'],
};

// Helper function to determine resource type by extension
const getResourceType = (extension: any) => {
  for (const [type, extensions] of Object.entries(extensionConfig)) {
    if (extensions.includes(extension)) {
      return type;
    }
  }
  return null; // Return null if the type is not found
};

const PreviewComponent: React.FC<PreviewComponentProps> = ({ resource, isOpen, onClose }) => {
  const [resourceUrl, setResourceUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setResourceUrl((resource as any).fileUrl); // Dynamically set file URL
    }
  }, [isOpen, resource]);

  const renderPreview = () => {
    const resourceType = getResourceType(resource.type);

    switch (resourceType) {
      // Video cases
      case 'video':
        return <video width="100%" controls src={resourceUrl || ''}></video>;
      
      // Audio cases
      case 'audio':
        return <audio controls src={resourceUrl || ''}></audio>;
      
      // Article case
      case 'article':
        return (
          <a href={resourceUrl || ''} target="_blank" rel="noopener noreferrer">
            {resource.resource_name}
          </a>
        );
      
      // PDF case
      case 'pdf':
        return resourceUrl ? (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
          <div style={{ height: '600px' }}>
            <Viewer fileUrl={resourceUrl} />
          </div>
        </Worker>
        
        ) : null;

      // Default case for unsupported types
      default:
        return <p>Unsupported resource type</p>;
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>
        <H4 style={{ whiteSpace: 'wrap', maxWidth: '80%' }}>
          {resource.display_name ? resource.display_name.split('.')[0] : resource.resource_name}
        </H4>
      </ModalHeader>
      <ModalBody>
        {renderPreview()}
        <Button color="secondary" onClick={onClose} style={{ marginTop: '10px' }}>
          Close
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default PreviewComponent;
