import React, { useEffect, useState } from "react";
import { Image, LI, P } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import ProfileBox from "./ProfileBox";
import axios from "axios";

const UserProfile = () => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    role: "",
    profilePhoto: null,
    mobileNumber: "",
    emailId: ""
  });

  useEffect(() => {
    const credentialId = localStorage.getItem("credentialId");
    const userId = localStorage.getItem("userId");
    console.log(credentialId,"cred");
    console.log(userId,"user");
  
    if (userId && credentialId) {
      axios.get(`https://uat.artiumacademy.com/api/admin/admin/${userId}/profileAPI?credid=${credentialId}`)
        .then((response) => {
          if (response.status === 200) {
            const { firstName, lastName, role, profilePhoto, mobileNumber, emailId } = response.data;
            setProfile({
              firstName,
              lastName,
              role,
              profilePhoto,
              mobileNumber,
              emailId
            });
          } else {
            console.error("Failed to fetch profile data");
          }
        })
        .catch((error) => {
          console.error("API Error: Failed to fetch profile data", error);
        });
    }
  }, []);
  

  return (
    <LI className="profile-nav onhover-dropdown px-0 py-0">
      <div className="d-flex profile-media align-items-center">
        <Image className="img-30" src={profile.profilePhoto || dynamicImage("dashboard/profile.png")} alt="user" />
        <div className="flex-grow-1">
          <span>{profile.firstName || "User"}</span>
      <P>  <span>{profile.emailId || "User"}</span></P>
           <P className="mb-0 font-outfit">
            {profile.role || "Role"}
            <i className="fa fa-angle-down" />
          </P>
        </div>
      </div>
      <ProfileBox />
    </LI>
  );
};

export default UserProfile;
