import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'reactstrap';
import { getSongURL, songByTags } from '../../../../Api/lms/apiUtils';
import { tagsMap} from './tags';
import Select from "react-select";

interface Song {
  song_id: number;
  song_name: string;
  karaoke_url: string;
  song_url: string;
  lyrics_id: number;
  annotation_id: number;
  kids_friendly: boolean;

}

interface SongSearchComponentProps {
  setSongs: any;
  
}

const SongSearchComponent: React.FC<SongSearchComponentProps> = ({ setSongs }) => {
  const [query, setQuery] = useState<string>('');
  const [filteredSongs, setFilteredSongs] = useState<Song[]>([]);
  const [displayedSongs, setDisplayedSongs] = useState<Song[]>([]);
  const [tagFilteredSongs, setTagFilteredSongs] = useState<Song[]>([]);
  const [selectedSongs, setSelectedSongs] = useState<Set<number>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState<'name' | 'tags'>('name'); 
  const { tagsList, isLoading: tagsLoading } = tagsMap()

  const tagOptions = tagsList.map((tag) => ({ value: tag.id, label: tag.name }));

  const lastSongRef = useCallback(
    (node: HTMLDivElement) => {
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMoreSongs();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [hasMore, isLoading]
  );

  const loadMoreSongs = async () => {
    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const response = await getSongURL(query, nextPage, 10);
      const newSongs = response.results || [];

      if (newSongs.length > 0) {
        setFilteredSongs(prevSongs => [...prevSongs, ...newSongs]);
        setDisplayedSongs(prevSongs => [...prevSongs, ...newSongs]);
        setPage(nextPage);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching more songs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSongs = async (searchQuery: string = '') => {
    setIsLoading(true);
    setPage(1);
    try {
      const response = await getSongURL(searchQuery, 1, 10);
      const newSongs = response.results || [];
      setFilteredSongs(newSongs);
      setDisplayedSongs(newSongs);
      setHasMore(newSongs.length === 10);
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSongsByTags = async () => {
    if (selectedTags.length === 0) return;
    setIsLoading(true);
    try {
      const response = await songByTags({
        tagIds: selectedTags,
        limit: 10,
        offset: 0,
      });
      const newSongs = response.data.songs || [];
      setTagFilteredSongs(newSongs);
    } catch (error) {
      console.error('Error fetching songs by tags:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTags.length === 0) {
      setTagFilteredSongs([]); // Clear songs when no tags are selected
    } else {
      // fetchSongsByTags(); // Fetch songs when tags are selected
    }
  }, [selectedTags]);
  

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (query) {
        fetchSongs(query);
      } else {
        fetchSongs();
      }
    }, 1000);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [query]);

  useEffect(() => {
    if (activeTab === 'name') {
      fetchSongs();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'tags' && selectedTags.length > 0) {
      fetchSongsByTags();
    }
  }, [activeTab, selectedTags]);


  
  const toggleSongSelection = (song_id: number) => {
    setSelectedSongs(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(song_id)) {
        newSelected.delete(song_id);
      } else {
        newSelected.add(song_id);
      }
      return newSelected;
    });
  };
  const addSelectedSongs = () => {
    
    const allSelected = [
      ...filteredSongs.filter(song => selectedSongs.has(song.song_id)),
      ...tagFilteredSongs.filter(song => selectedSongs.has(song.song_id)),
    ];
    setSongs(allSelected);
    setSelectedSongs(new Set());
  };

  return (
    <div>
    <div style={{ display: 'flex', marginBottom: '20px' }}>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'name' ? '#e82c86' : '#f0f0f0',
            color: activeTab === 'name' ? '#fff' : '#000',
            border: '1px solid #ccc',
          }}
          onClick={() => setActiveTab('name')}
        >
          Search by Name
        </button>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'tags' ? '#e82c86' : '#f0f0f0',
            color: activeTab === 'tags' ? '#fff' : '#000',
            border: '1px solid #ccc',
          }}
          onClick={() => setActiveTab('tags')}
        >
          Search by Tags
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'name' && (
        <div>
          <input
            type="text"
            placeholder="Search By Song Name...."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
          />

          {isLoading && <p>Loading...</p>}

          <div style={{ height: '200px', overflowY: 'auto', border: '1px solid #ccc', marginBottom: '10px' }}>
            {displayedSongs.map(song => (
              <div key={song.song_id} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <input
                  type="checkbox"
                  checked={selectedSongs.has(song.song_id)}
                  onChange={() => toggleSongSelection(song.song_id)}
                  style={{ marginRight: '10px' }}
                />
                <h3>{song.song_name}</h3>
              </div>
            ))}
          </div>
        </div>
      )}

{activeTab === 'tags' && (
  <div>
    <Select
      isMulti
      options={tagOptions}
      onChange={(selectedOptions) => {
        const tags = selectedOptions.map(option => option.value);
        setSelectedTags(tags); // Update selected tags
      }}
      placeholder="Select tags"
    />

    {isLoading && <p>Loading...</p>}

    <div style={{ height: '200px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '10px' }}>
      {tagFilteredSongs.length > 0 ? (
        tagFilteredSongs.map(song => (
          <div key={song.song_id} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
            <input
              type="checkbox"
              checked={selectedSongs.has(song.song_id)}
              onChange={() => toggleSongSelection(song.song_id)}
              style={{ marginRight: '10px' }}
            />
            <h3>{song.song_name}</h3>
          </div>
        ))
      ) : (
        <p>No Data</p>
      )}
    </div>
  </div>
)}


      <Button color="primary" onClick={addSelectedSongs} style={{ marginTop: '20px' }}>
        Add Selected Songs
      </Button>
    </div>
  );
};

export default SongSearchComponent;
