import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroup, ListGroupItem,
} from 'reactstrap';

import { GiMusicalScore } from "react-icons/gi";
import { Plus, Music, Youtube, FileText, Eye } from 'react-feather';
import { getSongURL, signedURL, uploadResourse, addResource, addSong, songByTags } from '../../../../Api/lms/apiUtils'; 
import PreviewModal from './songpreview';
import { LuMic2 } from "react-icons/lu";
import { toast } from "react-toastify";
import Select, { MultiValue } from "react-select";
import { tagsMap } from './tags';
import classnames from 'classnames';

interface Song {
  song_id: any;
  song_name: any;
  tags:any;
  backingTrackUrl:any;
  backingTrackTwoUrl:any;
  backingTrackOneUrl:any;
  karaokeVideoUrl:any;
  karaokeAudioUrl:any;
  mainSongUrl:any;
  song_url: any;
song:any;
  karaoke_url: any;
  annotationFileUrl: any | null;
  lyrics_id: number;
  annotation_id: number;
  kids_friendly: boolean;
  lyricsFile: any | null;
  annotationFile: any | null;
 lyricsFileUrl:any|null;
 notation_link: any ;
 demoTrackUrl:any;
}

interface TagOption {
  value: number;
  label: string;
}

function SongsList() {
  const [songs, setSongs] = useState<Song[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [addSongTags, setAddSongTags] = useState<any[]>([]);
  const [searchTags, setSearchTags] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('1');
  const[songActive, setSongActive] = useState<any>('1');
  const lastSongRef = useRef<HTMLDivElement | null>(null);
  const { tagsList, isLoading: tagsLoading } = tagsMap()
  const [songModalOpen, setModalSongOpen] = useState(false);

const [selectedSong, setSelectedSong] = useState(null); 
  const tagOptions = tagsList.map((tag) => ({ value: tag.id, label: tag.name }));

  const toggleSongModal = () => {

    setModalSongOpen(!songModalOpen);
  }
  const handleViewSongDetails = (song : any) => {
    setSelectedSong(song);  // Set the selected song when the button is clicked
    toggleSongModal();          // Open the modal
  };

  const songDetails: { key: keyof Song; label: any; newTab?: boolean }[] = [
    { key: "song_url", label: "Main Song Link", newTab: true }, // open in new tab
    { key: "karaoke_url", label: "Karaoke Link", newTab: true }, // open in new tab
    { key: "notation_link", label: "Notation", newTab: true }, // open in new tab
    { key: "mainSongUrl", label: "Main Song File" },
    { key: "annotationFileUrl", label: "Notation File" },
    { key: "lyricsFileUrl", label: "Lyrics" },
    { key: "demoTrackUrl", label: "Demo Track" },
    { key: "backingTrackOneUrl", label: "Backing Track One" },
    { key: "backingTrackTwoUrl", label: "Backing Track Two" },
    { key: "karaokeAudioUrl", label: "Karaoke Audio" },
    { key: "karaokeVideoUrl", label: "Karaoke Video" },
    { key: "backingTrackUrl", label: "Backing Track" },
  ];

  const [song, setSong] = useState({
    name: '',
    mainLink: '',
    karaokeLink: '',
    lyricsFile: null as File | null,
    annotationFile: null as File | null,
    kidFriendly: false,
    notation_link: '',
    mainSongFile: null as File | null,
    demoTrackFile: null as File | null,
    karaokeAudioFile: null as File | null,
    karaokeVideoFile: null as File | null,
    backingTrackFile: null as File | null,
    backingTrackOneFile: null as File | null,
    backingTrackTwoFile: null as File | null,

  });

  const toggleSongTab = (tab: string) => {
    setSongActive(tab);
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSongs([]);
      setPage(1);
      setHasMore(true);
    
      fetchSongs(1);  // Fetch all songs
    }
  };

  const toggleSTab = (tab: string) => {
    if (activeTab !== tab) {
     // Fetch all songs
    }
  };

  const fetchSongs = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const response = await getSongURL(searchQuery, pageNumber, 20);
      const newSongs = response.results || [];
      setSongs((prevSongs) => [...prevSongs, ...newSongs]);
      if (newSongs.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSongsByTags = async (tags: MultiValue<TagOption>) => {
    // Ensure we have a valid tag list
    if (tags.length === 0) {
      setSongs([]);  // Clear songs if no tags are selected
      setHasMore(false);
      return;
    }
  
    setIsLoading(true);
    try {
      const response = await songByTags({
        tagIds: tags.map(tag => tag.value),
        limit: 20,
        offset: (page - 1) * 20,
      });
      const newSongs = response.data.songs || [];
      setSongs((prevSongs) => [...prevSongs, ...newSongs]);
      if (newSongs.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching songs by tags:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(1);
    setHasMore(true);
    setSongs([]);
    fetchSongs(1);
  };
  

  const handleAddSongTagChange = (selectedOptions: MultiValue<TagOption>) => {
    setAddSongTags([...selectedOptions]);
  };
  const handleSearchTagChange = (selectedOptions: MultiValue<TagOption>) => {
    const updatedTags = Array.from(selectedOptions || []);
    setSearchTags(updatedTags); 
  
    // Clear current songs and reset pagination
    setPage(1);
    setHasMore(true);
    setSongs([]);
  
    // Trigger fetch after state update
    fetchSongsByTags(updatedTags);
  };
  
  const handleScroll = () => {
    if (
      lastSongRef.current &&
      lastSongRef.current.getBoundingClientRect().bottom <= window.innerHeight + 100 &&
      hasMore &&
      !isLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (activeTab === '1') {
      fetchSongs(page);
    } else {
      // fetchSongsByTags(searchTags);
    }
  }, [page, searchTags]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  const handleAddSong = async (song: any, addSongTags: any) => {
    let lyrics_id, annotation_id, mainSongId, karaokeAudioId, karaokeVideoId, backingTrackId, backingTrackOneId, backingTrackTwoId, demoTrackId;
    setIsLoading(true);
    setIsSaving(true); 
    
    try {
      // Upload lyrics file
      if (song.lyricsFile) {
        const lyricsResponse = await uploadFile(song.lyricsFile);
        lyrics_id = lyricsResponse?.data?.resource[0]?.resource_id;
        console.log("Lyrics ID:", lyrics_id);
      }
  
      // Upload annotation file
      if (song.annotationFile) {
        const annotationResponse = await uploadFile(song.annotationFile);
        annotation_id = annotationResponse?.data?.resource[0]?.resource_id;
        console.log("Annotation ID:", annotation_id);
      }
  
      // Upload main song file
      if (song.mainSongFile) {
        const mainSongResponse = await uploadFile(song.mainSongFile);
        mainSongId = mainSongResponse?.data?.resource[0]?.resource_id;
        console.log("Main Song ID:", mainSongId);
      }
  
      // Upload demo track
      if (song.demoTrackFile) {
        const demoTrackResponse = await uploadFile(song.demoTrackFile);
        demoTrackId = demoTrackResponse?.data?.resource[0]?.resource_id;
        console.log("Demo Track ID:", demoTrackId);
      }
  
      // Upload karaoke audio file
      if (song.karaokeAudioFile) {
        const karaokeAudioResponse = await uploadFile(song.karaokeAudioFile);
        karaokeAudioId = karaokeAudioResponse?.data?.resource[0]?.resource_id;
        console.log("Karaoke Audio ID:", karaokeAudioId);
      }
  
      // Upload karaoke video file
      if (song.karaokeVideoFile) {
        const karaokeVideoResponse = await uploadFile(song.karaokeVideoFile);
        karaokeVideoId = karaokeVideoResponse?.data?.resource[0]?.resource_id;
        console.log("Karaoke Video ID:", karaokeVideoId);
      }
  
      // Upload backing track (main)
      if (song.backingTrackFile) {
        const backingTrackResponse = await uploadFile(song.backingTrackFile);
        backingTrackId = backingTrackResponse?.data?.resource[0]?.resource_id;
        console.log("Backing Track ID:", backingTrackId);
      }
  
      // Upload backing track 1
      if (song.backingTrackOneFile) {
        const backingTrackOneResponse = await uploadFile(song.backingTrackOneFile);
        backingTrackOneId = backingTrackOneResponse?.data?.resource[0]?.resource_id;
        console.log("Backing Track One ID:", backingTrackOneId);
      }
  
      // Upload backing track 2
      if (song.backingTrackTwoFile) {
        const backingTrackTwoResponse = await uploadFile(song.backingTrackTwoFile);
        backingTrackTwoId = backingTrackTwoResponse?.data?.resource[0]?.resource_id;
        console.log("Backing Track Two ID:", backingTrackTwoId);
      }
  
      // Construct the song object with all the new files and fields
      const newSong = {
        songName: song.name,
        songUrl: song.mainLink,
        karaokeUrl: song.karaokeLink,
        lyricsId: lyrics_id,
        annotationId: annotation_id,
        mainSongId: mainSongId,
        demoTrackId: demoTrackId,
        karaokeAudioId: karaokeAudioId,
        karaokeVideoId: karaokeVideoId,
        backingTrackId: backingTrackId,
        backingTrackOneId: backingTrackOneId,
        backingTrackTwoId: backingTrackTwoId,
        notation_link: song.notation_link,
        kidsFriendly: song.kidFriendly,
        tags: addSongTags.map((tag:any) => tag.value),
      };
  

      console.log("New Song Payload:", newSong);
  
      // Add the new song to your database
      await addSong(newSong);
      console.log("Song successfully added!");
      toast.success('Song added successfully!');
      
      // Reset song data after successful addition
      setSong({
        name: '',
        mainLink: '',
        karaokeLink: '',
        lyricsFile: null,
        annotationFile: null,
        kidFriendly: false,
        notation_link: '',
        mainSongFile: null,
        demoTrackFile: null,
        karaokeAudioFile: null,
        karaokeVideoFile: null,
        backingTrackFile:null,
        backingTrackOneFile:null,
        backingTrackTwoFile: null,
      });
  
      // Optionally close the modal or update UI
      toggleModal();
    } catch (error) {
      console.error("Error adding song:", error);
      toast.error('Error adding song');
    } finally {
      setIsLoading(false);
      setIsSaving(false);
    }
  };
    
  
  

  const handlePreview = (fileUrl: any) => {
    setPreviewUrl(fileUrl);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewUrl(null);
  };

  const uploadFile = async (file: File) => {
    try {
      const fileExtension = file.name.split('.').pop();
      let fName = file.name.split(' ').join('_');
      const response = await signedURL({ fileName: fName, fileType: fileExtension });
      if (response.status === 200) {
        let resourseName = response.data.fileName;
        const { signedUrl } = response.data;
        const uploadResponse = await uploadResourse(signedUrl, file);
        if (uploadResponse.status === 200) {
          const resourceResponse = await addResource({
            resourceName: resourseName,
            displayName: file.name,
            type: fileExtension,
            description: "",
            url: "",
          });
          if (resourceResponse.status === 200) {
            return resourceResponse;
          }
        }
      }
    } catch (error) {
      console.error('Error during file upload process', error);
    }
    return null;
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleTab('1')}
          >
            Search by Songs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleTab('2')}
          >
            Search by Tag
          </NavLink>
        </NavItem>
      </Nav>
      <div style={{ marginTop: '10px', textAlign: 'left' }}>
  <Button color="primary" onClick={toggleModal}>
    Add Song
  </Button>
</div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FormGroup style={{ marginTop: '10px' }}>
            <Input
              type="text"
              placeholder="Search By Song Name..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </FormGroup>
        </TabPane>
        <TabPane tabId="2">
          <FormGroup style={{ marginTop: '10px' }}>
            <Select
              isMulti
              options={tagOptions}
              onChange={handleSearchTagChange}
              placeholder="Search by tags..."
            />
          </FormGroup>
        </TabPane>
      </TabContent>

      {/* <Button color="primary" onClick={toggleModal} style={{ marginBottom: '10px' }}>
        <Plus size={18} /> Add Song
      </Button> */}

      {isLoading && songs.length === 0 ? (
        <Row className="user-cards-items user-card-wrapper" style={{ marginTop: '15px' }}>
          <Col xs="12" className="text-center">
            <Spinner color="primary" />
            <p>Loading songs...</p>
          </Col>
        </Row>
      ) : (
        <Row className="user-cards-items user-card-wrapper">
          {songs.length > 0 ? (
            songs.map((song, index) => (
              <Col
                key={song.song_id}
                xl="4"
                sm="6"
                xxl="3"
                className="col-ed-4 box-col-4"
              >
              <div ref={index === songs.length - 1 ? lastSongRef : null}>
  <Card style={{ maxHeight: '80vh', height: '200px', overflowY: 'hidden', marginTop: '20px', position: 'relative' }}>
    <CardBody>


      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Song Name */}
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <h6 style={{ marginLeft: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {song.song_name}
          </h6>
        </div>

        <Button color="secondary" onClick={() => handleViewSongDetails(song)}>
          <Eye size={18} />
               </Button>
     
      </div>

   
    
      <div style={{ marginTop: '10px' }}>
        {Array.isArray(song.tags) && song.tags.filter((tag) => tag).length > 0 ? (
          <div>
            <strong>Tags:</strong>
            <div style={{ marginLeft: '5px', display: 'flex', flexWrap: 'wrap', gap: '5px', maxHeight: '80px', overflowY: 'auto' }}>

              {song.tags.filter((tag) => tag).map((tag, index) => (
                <span key={index} style={{ backgroundColor: '#f1f1f1', borderRadius: '12px', padding: '4px 10px', fontSize: '12px', color: '#333' }}>
                {tag}
              </span>
              ))}
            </div>
          </div>
        ) : (
          <div style={{ fontStyle: 'italic', color: '#888' }}>No tags available</div>
        )}
      </div>
    </CardBody>
  </Card>
</div>

              </Col>
            ))
          ) : (
            <p>No songs available.</p>
          )}
        </Row>
      )}
<Modal isOpen={songModalOpen} toggle={toggleSongModal} className="custom-modal">
  <ModalHeader toggle={toggleSongModal}>Song details</ModalHeader>
  <ModalBody>
    {selectedSong && (
      <ListGroup>
        {songDetails.map(({ key, label, newTab }) =>
          selectedSong[key] ? (
            <ListGroupItem tag="div" action key={key}>
              {newTab ? (
                <a href={selectedSong[key]} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              ) : (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePreview(selectedSong[key]);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {label}
                </span>
              )}
            </ListGroupItem>
          ) : null
        )}
      </ListGroup>
    )}
  </ModalBody>
  <ModalFooter>
    <Button color="secondary" onClick={toggleSongModal}>
      Close
    </Button>
  </ModalFooter>
</Modal>

{/* 
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Song</ModalHeader>
        <ModalBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <FormGroup className="mb-3">
            <Label for={`songName`}><b>Enter Song Name</b></Label>
            <Input
              type="text"
              id={`songName`}
              value={song.name}
              onChange={(e) => setSong({ ...song, name: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for={`mainLink`}><b>Main Song Link</b></Label>
            <Input
              type="url"
              id={`mainLink`}
              value={song.mainLink}
              onChange={(e) => setSong({ ...song, mainLink: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for={`karaokeLink`}><b>Karaoke Song Link</b></Label>
            <Input
              type="url"
              id={`karaokeLink`}
              value={song.karaokeLink}
              onChange={(e) => setSong({ ...song, karaokeLink: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for={`uploadLyrics`}><b>Upload Lyrics</b></Label>
            <Input
              type="file"
              accept=".pdf"
              id={`uploadLyrics`}
              onChange={(e) => setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for={`notationLink`}><b>Notation Link</b></Label>
            <Input
              type="url"
              id="notationLink"
              value={song.notation_link}
              onChange={(e) => setSong({ ...song, notation_link: e.target.value })}
            />
          </FormGroup>
          <Label for={`uploadAnnotation`}><b>Notation File</b></Label>
          <FormGroup>
            <Input
              type="file"
              accept=".pdf"
              id={`uploadAnnotation`}
              onChange={(e) => setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })}
            />
          </FormGroup>
          <Label for={`tags`}><b>Select Tags</b></Label>
          <FormGroup>
            <Select
              isMulti
              options={tagOptions}
              onChange={handleAddSongTagChange}
              placeholder="Select tags"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddSong} disabled={isSaving}>
            {isSaving ? <Spinner size="sm" /> : 'Save'}
          </Button>
        </ModalFooter>
      </Modal> */}

<Modal isOpen={isModalOpen} toggle={toggleModal}>
  <ModalHeader toggle={toggleModal}>Add Song</ModalHeader>

  {/* Dropdown for selecting mode */}
  <FormGroup>
    {/* <Label><b>Select Mode</b></Label> */}
    <Input
    style={{width:"200px", marginTop:"10px", marginLeft:"10px"}}
      type="select"
      value={songActive}
      onChange={(e) => toggleSongTab(e.target.value)}
    >
      <option value="1">Add Link</option>
      <option value="2">Upload File</option>
    </Input>
  </FormGroup>

  <ModalBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
    {songActive === '1' && (
      <>
        {/* Add Link Section */}
        <FormGroup>
          <Label><b>Enter Song Name</b></Label>
          <Input
            type="text"
            value={song.name}
            onChange={(e) => setSong({ ...song, name: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Main Song Link</b></Label>
          <Input
            type="url"
            value={song.mainLink}
            onChange={(e) => setSong({ ...song, mainLink: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Karaoke Song Link</b></Label>
          <Input
            type="url"
            value={song.karaokeLink}
            onChange={(e) => setSong({ ...song, karaokeLink: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Notation Link</b></Label>
          <Input
            type="url"
            value={song.notation_link}
            onChange={(e) => setSong({ ...song, notation_link: e.target.value })}
          />
        </FormGroup>
        
        <FormGroup >
          <Label><b>Demo Track</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"          
              onChange={(e) => setSong({ ...song, demoTrackFile: e.target.files ? e.target.files[0] : null })}
       
          />
        </FormGroup>
      <FormGroup >
          <Label><b>Backing Track Original Tempo</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"            // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackFile: e.target.files ? e.target.files[0] : null })}
            // }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Karaoke Audio</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"
            onChange={(e) =>
              setSong({ ...song, karaokeAudioFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Karaoke Video</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"
            onChange={(e) =>
              setSong({ ...song, karaokeVideoFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Backing Track Tempo 1</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"            // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackOneFile: e.target.files ? e.target.files[0] : null })}
            />
        </FormGroup>
        <FormGroup ><Label><b>Backing Track Tempo 2</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"           
              onChange={(e) => setSong({ ...song, backingTrackTwoFile: e.target.files ? e.target.files[0] : null })}
         
          />
        </FormGroup>

        
        <FormGroup>
          <Label><b>Lyrics</b></Label>
          <Input
            type="file"
            accept=".pdf"
            onChange={(e) =>
              setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Notation File</b></Label>
          <Input
            type="file"
            accept=".pdf"
            onChange={(e) =>
              setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
       
      </>
    )}

    {songActive === '2' && (
      <>
        {/* Upload File Section */}
        <FormGroup>
          <Label><b>Enter Song Name</b></Label>
          <Input
            type="text"
            value={song.name}
            onChange={(e) => setSong({ ...song, name: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Main Song File</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav"
            // onChange={(e) =>
            //   setSong({ ...song, mainLink: e.target.files ? e.target.files[0] : null })
            // }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Demo Track</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"          
            onChange={(e) => setSong({ ...song, demoTrackFile: e.target.files ? e.target.files[0] : null })}

          />
        </FormGroup>
      <FormGroup >
          <Label><b>Backing Track Original Tempo</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"            // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackFile: e.target.files ? e.target.files[0] : null })}

          />
        </FormGroup>

        <FormGroup >
          <Label><b>Backing Track Tempo 1</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"            // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackOneFile: e.target.files ? e.target.files[0] : null })}
            // }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Backing Track Tempo 2</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"            // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackTwoFile: e.target.files ? e.target.files[0] : null })}
            // }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Karaoke Audio</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"
            onChange={(e) =>
              setSong({ ...song, karaokeAudioFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup >
          <Label><b>Karaoke Video</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"
            onChange={(e) =>
              setSong({ ...song, karaokeVideoFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Upload Lyrics</b></Label>
          <Input
            type="file"
            accept=".pdf"
            onChange={(e) =>
              setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Upload Notation File</b></Label>
          <Input
            type="file"
            accept=".pdf"
            onChange={(e) =>
              setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
      </>
    )}

    <FormGroup>
      <Label><b>Select Tags</b></Label>
      <Select
        isMulti
        options={tagOptions}
        onChange={handleAddSongTagChange}
        placeholder="Select tags"
      />
    </FormGroup>
  </ModalBody>

  <ModalFooter>
    <Button
      color="primary"
      onClick={() => handleAddSong(song, addSongTags)}
      disabled={isSaving}
    >
      {isSaving ? <Spinner size="sm" /> : 'Save'}
    </Button>
  </ModalFooter>
</Modal>


      {previewUrl && (
        <PreviewModal
          isOpen={isPreviewOpen}
          toggle={closePreview}
          fileUrl={previewUrl}
          title="Lyrics Preview"
        />
      )}
    </div>
  );
}

export default SongsList;
