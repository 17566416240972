import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { getAssignmentRubrics, deleteRubricFromAssignment } from '../../../../Api/lms/apiUtils';
import { toast } from "react-toastify";
import { Trash } from 'react-feather';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface Rubric {
  rubric_id: number;
  rubric_name: string;
}

interface RubricsAssignmentProps {
  assignmentId: number;
  lessonId: number;
  refresh: number;
}

export interface RubricsAssignmentRef {
  fetchRubrics: () => Promise<void>;
}


const RubricsAssignment = forwardRef<RubricsAssignmentRef, RubricsAssignmentProps>(
  ({ assignmentId, lessonId, refresh  }, ref) => {
    const [rubrics, setRubrics] = useState<Rubric[]>([]);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedRubricId, setSelectedRubricId] = useState<number | null>(null);

    useImperativeHandle(ref, () => ({
      fetchRubrics,
    }));

    useEffect(() => {
      fetchRubrics();
    }, [assignmentId, refresh]);

    const fetchRubrics = async () => {
      try {
        const response = await getAssignmentRubrics(assignmentId);
        if (response?.rubrics && Array.isArray(response.rubrics)) {
          setRubrics(response.rubrics);
        } else {
          toast.error('Failed to fetch rubrics for the assignment.');
        }
      } catch (error) {
        console.error('Error fetching rubrics:', error);
    
      }
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const confirmDeleteRubric = (rubricId: number) => {
      setSelectedRubricId(rubricId);
      toggleModal();
    };

    const handleDeleteRubric = async () => {
      if (selectedRubricId === null) return;

      const requestBody = {
        rubricId: selectedRubricId,
        assignmentId,
        lessonId,
      };

      setIsDeleting(true);

      try {
        const response = await deleteRubricFromAssignment(requestBody);
        if (response.status === 200) {
          toast.success('Rubric removed successfully');
          setRubrics((prevRubrics) =>
            prevRubrics.filter((rubric) => rubric.rubric_id !== selectedRubricId)
          );
          toggleModal();
        } else {
          toast.error('Failed to remove rubric.');
        }
      } catch (error) {
        console.error('Error deleting rubric:', error);
        toast.error('Error deleting rubric.');
      } finally {
        setIsDeleting(false);
        setSelectedRubricId(null);
      }
    };

    return (
      <div>
<div style={{ marginBottom: "10px" }}>
  <div style={{ marginBottom: "10px" }}>
    <strong style={{ marginLeft: "-4px" }}>Rubrics List:</strong>
  </div>
  <div style={{ marginLeft: "-4px" }}>
    {rubrics.map((rubric) => (
      <div
        key={rubric.rubric_id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        // Adds space between rubrics
        }}
      >
        <span>{rubric.rubric_name}</span>
        <Trash
          size={14}
          style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}
          onClick={() => confirmDeleteRubric(rubric.rubric_id)}
        />
      </div>
    ))}
  </div>
</div>



        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this rubric? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDeleteRubric} disabled={isDeleting}>
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
            <Button color="secondary" onClick={toggleModal} disabled={isDeleting}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);

export default RubricsAssignment;
