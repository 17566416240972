import React, { ChangeEvent } from 'react';
// import { useAppSelector, useAppDispatch } from 'your-redux-hooks-path'; // Replace with your actual hook import paths
import { Col, Row, Form, Label, Input, FormGroup, Card, CardBody } from 'reactstrap';
// import { setBasicInputFormValue, handleBackButton, handleNextButton } from 'your-actions-path'; // Replace with your actual action import paths

const NumberingWizard = () => {
  // const { numberLevel, basicInputFormValue, showFinish } = useAppSelector((state) => state.numberingWizard);
  // const dispatch = useAppDispatch();
  const stepperHorizontalData = ["Basic Info", "Cart Info", "Feedback", "Finish"];

  const getUserData = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value =
      name === "agreeTerms" ||
      name === "informationCheckBox" ||
      name === "agreeConditions"
        ? event.target.checked
        : name === "uploadDocumentation"
        ? event.target.files && event.target.files[0].name
        : event.target.value;
    // dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: value }));
  };

  return (
   <div  className='page-body'>
     <Card className="height-equal">
      <CardBody className="basic-wizard important-validation">
        <div className="stepper-horizontal">

            <div className={`stepper-one stepper step`}>
              <div className="step-circle">
                <span></span>
              </div>
              <div className="step-title"></div>
              <div className="step-bar-left" />
              <div className="step-bar-right" />
            </div>
        
        </div>
        <div id="msform">
       
            <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
              <Row>
                <Col sm="6" className="mb-3">
                  <Label>{/* Email Label */}<span className="txt-danger">*</span></Label>
                  <Input onChange={getUserData}name="email" type="email" placeholder="Enter your email" />
                </Col>
                <Col sm="6" className="mb-3">
                  <Label>{/* First Name Label */}<span className="txt-danger">*</span></Label>
                  <Input onChange={getUserData} name="firstName" type="text" placeholder="Enter your first name" />
                </Col>
                <Col xs="12" className="mb-3">
                  <Label>{/* Password Label */}<span className="txt-danger">*</span></Label>
                  <Input onChange={getUserData} name="password" type="password" placeholder="Enter your password" />
                </Col>
                <Col xs="12" className="mb-3">
                  <Label>{/* Confirm Password Label */}<span className="txt-danger">*</span></Label>
                  <Input onChange={getUserData}name="confirmPassword" type="password" placeholder="Confirm your password" />
                </Col>
                <Col xs="12" className="mb-3">
                  <FormGroup check>
                    <Input id="inputCheckWizard" name="agreeTerms" onChange={getUserData} type="checkbox"  required />
                    <Label className="mb-0" htmlFor="inputCheckWizard" check>
                      {/* Agree to Terms and Conditions */}
                      Agree to Terms and Conditions
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
       
          {/* {numberLevel === 2 && <CartInfoForm getUserData={getUserData} basicInputFormValue={basicInputFormValue} />}
          {numberLevel === 3 && <FeedbackForm getUserData={getUserData} basicInputFormValue={basicInputFormValue} />}
          {numberLevel === 4 && <FinishForm />} */}
        </div>
        <div className="wizard-footer d-flex gap-2 justify-content-end">
          
            <button className="alert-light-primary">
              Back
            </button>
   
          <button  color="primary" >
            {/* {showFinish ? "Finish" : "Next"} */}
          </button>
        </div>
      </CardBody>
    </Card>
   </div>
  );
};

export default NumberingWizard;
