import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface PreviewModalProps {
  isOpen: boolean;
  toggle: () => void;
  fileUrl: string;
  title: string;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ isOpen, toggle, fileUrl, title }) => {
  const fileExtension = fileUrl.split('.').pop()?.toLowerCase();

  const isPdf = fileExtension === 'pdf';
  const isAudio = ['mp3', 'wav', 'm4a', ].includes(fileExtension || '');
  const isVideo = ['mp4', 'mov', 'avi', 'mkv'].includes(fileExtension || '');

  const getMimeType = (extension: string | undefined) => {
    switch (extension) {
      case 'mp3':
        return 'audio/mpeg';
      case 'wav':
        return 'audio/wav';
      case 'm4a':
        return 'audio/mp4'; // Common MIME type for M4A
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      case 'avi':
        return 'video/x-msvideo';
      case 'mkv':
        return 'video/x-matroska';
      default:
        return '';
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {fileUrl ? (
          isPdf ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer fileUrl={fileUrl} />
            </Worker>
          ) : isAudio ? (
            <audio controls style={{ width: '100%' }}>
              <source src={fileUrl} type={getMimeType(fileExtension)} />
              Your browser does not support the audio element.
            </audio>
          ) : isVideo ? (
            <video controls style={{ width: '100%' }}>
              <source src={fileUrl} type={getMimeType(fileExtension)} />
              Your browser does not support the video element.
            </video>
          ) : (
            <img src={fileUrl} alt={title} style={{ width: '100%', height: 'auto' }} />
          )
        ) : (
          <p>No file to preview</p>
        )}
      </ModalBody>
      <div style={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </div>
    </Modal>
  );
};

export default PreviewModal;
