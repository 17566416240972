// SonglistCard.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Card, CardBody, CardHeader, Button, Input, FormGroup, Label, Row, Col, Collapse, Modal, ListGroup, ListGroupItem, ModalHeader, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import { Trash, Eye, Plus, Music, Youtube, FileText, Video } from 'react-feather';
import Select, { MultiValue } from "react-select";
import './style.scss';
import { signedURL, uploadResourse, addResource, addSong, getSongLevel, addSongWithLevel, removeSongFromLevel, editSong, getTags } from '../../../../Api/lms/apiUtils';
import SongSearchComponent from './searchSong';
import { toBeRequired } from '@testing-library/jest-dom/matchers';
import PreviewModal from './songpreview';
import { toast } from "react-toastify";
import { LuMic2 } from "react-icons/lu";
import { GiMusicalScore } from "react-icons/gi";
import { CiEdit } from "react-icons/ci";
import { tagsMap } from './tags';
import { IoMusicalNotes } from 'react-icons/io5'; 
import { FaMusic, FaMicrophone, FaHeadphones, FaVideo } from 'react-icons/fa';




interface TagOption {
  value: number;
  label: string;
}


interface Song {
  name: string;
  mainLink: string;
  karaokeLink: string;
  lyricsFile: File | null;
  annotationFile: File | null; 
  kidFriendly: boolean;
  notation_link: any;
  karaokeVideoFile: File | null;
  backingTrackFile:File | null;
  backingTrackOneFile:File | null
  backingTrackTwoFile:File | null;
  mainSongFile:File | null;
  demoTrackFile:File | null;
  karaokeAudioFile:File | null;


  
  
}



interface FetchedSong {
  song_id: any;
  song_name: any;
  backingTrackFile:any;
  backingTrackOneFile:any;
  backingTrackTwoFile:any;
  demoTrackFile:any;
mainSongFile:any;
  tags:any;
  backingTrackUrl:any;
  backingTrackTwoUrl:any;
  backingTrackOneUrl:any;
  karaokeVideoUrl:any;
  karaokeAudioUrl:any;
  karaokeVideoFile: any;
  karaokeAudioFile:any
  mainSongUrl:any;
  song_url: any;
song:any;
  karaoke_url: any;
  annotationFileUrl: any | null;
  lyrics_id: number;
  annotation_id: number;
  main_song_id: number;
  demo_track_id: number;
  karaoke_audio_id: number;
  karaoke_video_id: number;
  backing_track_id: number;
  backing_track_one_id: number;
  backing_track_two_id: number;

  kids_friendly: boolean;
  lyricsFile: any | null;
  annotationFile: any | null;
 lyricsFileUrl:any|null;
 notation_link: any ;
 demoTrackUrl:any;


}


interface SonglistCardProps {
  toggle: () => void;
  levelId: number;
}

const SonglistCard: React.FC<SonglistCardProps> = ({ toggle, levelId, }) => {
  const [songs, setSongs] = useState<Song[]>([
    {
      name: '',
      mainLink: '',
      karaokeLink: '',
      notation_link: '',
      lyricsFile: null,
      annotationFile: null,
      mainSongFile: null,
      demoTrackFile: null,
      karaokeAudioFile: null,
      karaokeVideoFile: null,
      backingTrackFile: null,
      backingTrackOneFile: null,
      backingTrackTwoFile: null,
      kidFriendly: false,
    }
  ]);
  
  const [song, setSong] = useState<Song>({
    name: '',
    mainLink: '',
    karaokeLink: '',
    notation_link: '',
    lyricsFile: null,
    annotationFile: null,
    mainSongFile: null,
    demoTrackFile: null,
    karaokeAudioFile: null,
    karaokeVideoFile: null,
    backingTrackFile: null,
    backingTrackOneFile: null,
    backingTrackTwoFile: null,
    kidFriendly: false,
  });
  const [songList, setSongList] = useState<FetchedSong[]>([]);
  const [isOpen, setIsOpen] = useState(false); 
  const [isSongListOpen, setIsSongListOpen] = useState(false); 
  const [addIsOpen, setAddIsOpen] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
const [songToDelete, setSongToDelete] = useState<FetchedSong | null>(null);
const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State to control preview modal
const [previewUrl, setPreviewUrl] = useState<string | null>(null); 
const [isUploading, setIsUploading] = useState(false); 
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [songToEdit, setSongToEdit] = useState<FetchedSong | null>(null);
const [isChecked, setIsChecked] = useState(false); // Checkbox state
const [tags, setTags] = useState([]); // State to store fetched tags
const [selectedTags, setSelectedTags] = useState<any[]>([]); // Use string[] instead of number[]
const [selectedEditTags, setSelectedEditTags] = useState<any[]>([]); // Use string[] instead of number[]

const[songActive, setSongActive] = useState<any>('1');
const [modalOpen, setModalOpen] = useState(false);

const [selectedSong, setSelectedSong] = useState(null); 

const initialSongState: Song = {
  name: '',
  mainLink: '',
  karaokeLink: '',
  notation_link: '',
  lyricsFile: null,
  annotationFile: null,
  mainSongFile: null,
  demoTrackFile: null,
  karaokeAudioFile: null,
  karaokeVideoFile: null,
  backingTrackFile: null,
  backingTrackOneFile: null,
  backingTrackTwoFile: null,
  kidFriendly: false,
};

const toggleModal = () => {

  setModalOpen(!modalOpen);
}
const handleViewSongDetails = (song : any) => {
  setSelectedSong(song);  // Set the selected song when the button is clicked
  toggleModal();          // Open the modal
};
const { tagsList, isLoading: tagsLoading } = tagsMap()
const tagOptions = tagsList.map((tag) => ({ value: tag.id, label: tag.name }));
  const fetchSongs = async () => {
    try {
      const response = await getSongLevel(levelId);
      if (response) {
        setSongList(response.songs);
      } else {
        console.log('Error fetching songs', response);
      }
    } catch (error) {
      console.error('Error fetching songs', error);
    }
  };
  
  useEffect(() => {
    if (songToEdit) {
      const initialTags = songToEdit.tags.map((tag : any) => ({ label: tag, value: tag })); // Convert tags to Select format // Set the initial selected tags when the modal is opened
    }
  }, [songToEdit]);
  

  useEffect(() => {
    fetchSongs();
  }, []);
  

  const songDetails: { key: keyof FetchedSong; label: any; newTab?: boolean }[] = [
    { key: "song_url", label: "Main Song Link", newTab: true }, // open in new tab
    { key: "karaoke_url", label: "Karaoke Link", newTab: true }, // open in new tab
    { key: "notation_link", label: "Notation Link", newTab: true }, // open in new tab
    { key: "mainSongUrl", label: "Main Song File" },
    { key: "annotationFileUrl", label: "Notation File" },
    { key: "lyricsFileUrl", label: "Lyrics File" },
    { key: "demoTrackUrl", label: "Demo Track" },
    { key: "backingTrackOneUrl", label: "Backing Track One" },
    { key: "backingTrackTwoUrl", label: "Backing Track Two" },
    { key: "karaokeAudioUrl", label: "Karaoke Audio" },
    { key: "karaokeVideoUrl", label: "Karaoke Video" },
    { key: "backingTrackUrl", label: "Backing Track" },
  ];
  


  const toggleSongTab = (tab: string) => {
    setSongActive(tab);
  };
  const handleTagChange = (selectedOptions : any ) => {
    setSelectedTags(selectedOptions); 
  };


  const handleEditSong = async () => {
    let lyrics_id, annotation_id, mainSongId, demoTrackId, karaokeAudioId, karaokeVideoId, backingTrackId, backingTrackOneId, backingTrackTwoId;
  
    if (!songToEdit) return;
  
    try {
      // Existing IDs
      let lyricsId = songToEdit.lyrics_id;
      let annotationId = songToEdit.annotation_id;
      let mainSongId = songToEdit.main_song_id;
      let demoTrackId = songToEdit.demo_track_id;
      let karaokeAudioId = songToEdit.karaoke_audio_id;
      let karaokeVideoId = songToEdit.karaoke_video_id;
      let backingTrackId = songToEdit.backing_track_id;
      let backingTrackOneId = songToEdit.backing_track_one_id;
      let backingTrackTwoId = songToEdit.backing_track_two_id;
  
      // Upload new or updated files if any
      if (songToEdit.lyricsFile) {
        const lyricsResponse = await uploadSongFile(songToEdit.lyricsFile);
        lyricsId = lyricsResponse?.data?.resource[0]?.resource_id || lyricsId;
      }
  
      if (songToEdit.annotationFile) {
        const annotationResponse = await uploadSongFile(songToEdit.annotationFile);
        annotationId = annotationResponse?.data?.resource[0]?.resource_id || annotationId;
      }
  
      if (songToEdit.mainSongFile) {
        const mainSongResponse = await uploadSongFile(songToEdit.mainSongFile);
        mainSongId = mainSongResponse?.data?.resource[0]?.resource_id || mainSongId;
      }
  
      if (songToEdit.demoTrackFile) {
        const demoTrackResponse = await uploadSongFile(songToEdit.demoTrackFile);
        demoTrackId = demoTrackResponse?.data?.resource[0]?.resource_id || demoTrackId;
      }
  
      if (songToEdit.karaokeAudioFile) {
        const karaokeAudioResponse = await uploadSongFile(songToEdit.karaokeAudioFile);
        karaokeAudioId = karaokeAudioResponse?.data?.resource[0]?.resource_id || karaokeAudioId;
      }
  
      if (songToEdit.karaokeVideoFile) {
        const karaokeVideoResponse = await uploadSongFile(songToEdit.karaokeVideoFile);
        karaokeVideoId = karaokeVideoResponse?.data?.resource[0]?.resource_id || karaokeVideoId;
      }
  
      if (songToEdit.backingTrackFile) {
        const backingTrackResponse = await uploadSongFile(songToEdit.backingTrackFile);
        backingTrackId = backingTrackResponse?.data?.resource[0]?.resource_id || backingTrackId;
      }
  
      if (songToEdit.backingTrackOneFile) {
        const backingTrackOneResponse = await uploadSongFile(songToEdit.backingTrackOneFile);
        backingTrackOneId = backingTrackOneResponse?.data?.resource[0]?.resource_id || backingTrackOneId;
      }
  
      if (songToEdit.backingTrackTwoFile) {
        const backingTrackTwoResponse = await uploadSongFile(songToEdit.backingTrackTwoFile);
        backingTrackTwoId = backingTrackTwoResponse?.data?.resource[0]?.resource_id || backingTrackTwoId;
      }
  
      // Construct the song object with all the new files and fields
      const body = {
        songName: songToEdit.song_name,
        songUrl: songToEdit.song_url,
        karaokeUrl: songToEdit.karaoke_url,
        lyricsId,
        annotationId,
        mainSongId,
        demoTrackId,
        karaokeAudioId,
        karaokeVideoId,
        backingTrackId,
        backingTrackOneId,
        backingTrackTwoId,
        notation_link: songToEdit.notation_link,
        kidsFriendly: songToEdit.kids_friendly,
        tags: selectedEditTags.map((tag) => tag.value),
      };
  
      // Call the editSong function with the updated body
      const response = await editSong(songToEdit.song_id, body);
  
      if (response.status === 200) {
        fetchSongs();
        toast.success('Song updated successfully');
        closeEditModal();
      } else {
        toast.error('Failed to update song');
      }
    } catch (error) {
      console.error('Error updating song', error);
      toast.error('Error updating song');
    }
  };
  
  
  
  const handleInputChange = (field: keyof FetchedSong, value: any) => {
    if (songToEdit) {
      setSongToEdit({ ...songToEdit, [field]: value });
    }
  };

  const openEditModal = (song: FetchedSong) => {
    setIsEditModalOpen(true);
    const validTags = Array.isArray(song.tags)
      ? song.tags.filter((tag: any) => tag !== null )
      : [];
  
    // Map valid tags to match the `tagOptions` structure
    const initialTags = validTags.map((tag: any) => {
      const matchingTag = tagOptions.find((option) => option.label === tag);
      return matchingTag ? matchingTag : { label: tag, value: tag }; // Fallback to using the tag as both label and value
    });
  
    setSelectedEditTags(initialTags); // Update the selected tags state
    setSongToEdit({ ...song }); // Set the song being edited
 // Open the modal
  };
  
  
  
  
  
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSongToEdit(null);
  };

  const handleAddFileChange = (file: File | null, key: string) => {
    if (file) {
      setSong({ ...song, [key]: file });
    }

  }


  const handleSongChange = (index: number, field: keyof Song, value: any) => {
    let updatedSong = { ...song };
    updatedSong = { ...updatedSong, [field]: value };
    setSong(updatedSong as any);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // This resets the input field.
    }
  };
  const _addSong = async (newSong: any) => {
    setIsUploading(true);
    try {
      const response = await addSong(newSong);
      if (response.status === 201) {
        const songResponse = await addSongWithLevel({
          songs: [response.data.songId],
          levelId,
        });
        if (songResponse.status === 200) {
          // Reset state and inputs
          setSong({
            name: '',
            mainLink: '',
            karaokeLink: '',
            notation_link: '',
            lyricsFile: null,
            annotationFile: null,
            mainSongFile: null,
            demoTrackFile: null,
            karaokeAudioFile: null,
            karaokeVideoFile: null,
            backingTrackFile: null,
            backingTrackOneFile: null,
            backingTrackTwoFile: null,
            kidFriendly: false,
          });
  
          document.querySelectorAll('input[type="file"]').forEach((input) => {
            (input as HTMLInputElement).value = '';
          });
          
          fetchSongs();
          setSelectedTags([]);
          setIsUploading(false);
          toast.success('Song added successfully');
        } else {
          console.error('Error adding song to level', songResponse);
        }
      } else {
        console.error('Error adding song', response);
      }
    } catch (error) {
      console.error('Error adding song', error);
      toast.error('Error adding song');
    }
  };
  

  const openDeleteModal = (song: FetchedSong) => {
    setSongToDelete(song);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSongToDelete(null);
  };
  
  const handleDeleteSong = async () => {
    if (!songToDelete) return;
    try {
      const response = await removeSongFromLevel(songToDelete.song_id, levelId);
    
      if (response.status === 200) {
        fetchSongs(); // Refresh the song list after deletion
        toast.success('Song removed successfully');
      } else {
        console.log('Error removing song from level', response);
        toast.error('Error removing song from level');
      }
    } catch (error) {
      console.error('Error removing song from level', error);
      toast.error('Error removing song from level');
      console.log("songId", songToDelete.song_id, levelId)
    } finally {
      closeDeleteModal(); // Close the modal after processing
    }
  };
  
  

  const handleAddExistingSongs = async (songsToAdd: FetchedSong[]) => {
    try {
      // Filter out songs that are already in the songList by checking their song_id
      const newSongsToAdd = songsToAdd.filter(song => 
        !songList.some(existingSong => existingSong.song_id === song.song_id)
      );
  
      if (newSongsToAdd.length === 0) {
        // Show a toast message or notification if all songs are already in the list
        toast.error(' selected songs are already in the list');
        return;
      }
  
      const songIds = newSongsToAdd.map(song => song.song_id);
      const response = await addSongWithLevel({
        songs: songIds,
        levelId: levelId,
      });
  
      if (response.status === 200) {
        fetchSongs(); // Refresh the song list
        toast.success('New songs added successfully');
      } else {
        console.log('Error adding existing songs to level', response);
      }
    } catch (error) {
      console.error('Error adding existing songs to level', error);
    }
  };
  const filteredSongs = songList.filter(song => 
    song.song_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  const uploadSongFile = async (file: File) => {
    try {
      if (file) {
        const fileExtension = file.name.split('.').pop();
        let fName = file.name.split(' ').join('_'); // Replace spaces with underscores
        const response = await signedURL({ fileName: fName, fileType: fileExtension });
        if (response.status === 200) {
          let resourseName = response.data.fileName; // Update the resource name to match what the server expects
          const { signedUrl } = response.data;
          const uploadResponse = await uploadResourse(signedUrl, file);
          if (uploadResponse.status === 200) {
            const resourceResponse = await addResource({
              resourceName: resourseName,
              displayName: file.name,
              type: fileExtension,
              description: "", // Add your resource description here
              url: "", // Optional URL
            });

            if (resourceResponse.status === 200) {
              return resourceResponse;
            } else {
              console.log('Error adding resource', resourceResponse);
              return null;
            }
          } else {
            console.log('Error uploading file', uploadResponse);
            return null;
          }
        }
      }
    } catch (error) {
      console.error('Error during file upload process', error);
    }
  };

  const handlePreview = (fileUrl:any) => {
    setPreviewUrl(fileUrl);
    setIsPreviewOpen(true);
  };
  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewUrl(null);
  };

  const handleAddSong = async () => {
    let lyrics_id, annotation_id, mainSongId, karaokeAudioId, karaokeVideoId, backingTrackId, backingTrackOneId, backingTrackTwoId, demoTrackId;
    setIsUploading(true);
    
    try {
      // Upload lyrics file
      if (song.lyricsFile) {
        const lyricsResponse = await uploadSongFile(song.lyricsFile);
        lyrics_id = lyricsResponse?.data?.resource[0]?.resource_id;
   
      }
  
      // Upload annotation file
      if (song.annotationFile) {
        const annotationResponse = await uploadSongFile(song.annotationFile);
        annotation_id = annotationResponse?.data?.resource[0]?.resource_id;

      }
  
      // Upload main song file
      if (song.mainSongFile) {
        const mainSongResponse = await uploadSongFile(song.mainSongFile);
        mainSongId = mainSongResponse?.data?.resource[0]?.resource_id;
     
      }
  
      // Upload demo track
      if (song.demoTrackFile) {
        const demoTrackResponse = await uploadSongFile(song.demoTrackFile);
        demoTrackId = demoTrackResponse?.data?.resource[0]?.resource_id;

      }
  
      // Upload karaoke audio file
      if (song.karaokeAudioFile) {
        const karaokeAudioResponse = await uploadSongFile(song.karaokeAudioFile);
        karaokeAudioId = karaokeAudioResponse?.data?.resource[0]?.resource_id;

      }
  
      // Upload karaoke video file
      if (song.karaokeVideoFile) {
        const karaokeVideoResponse = await uploadSongFile(song.karaokeVideoFile);
        karaokeVideoId = karaokeVideoResponse?.data?.resource[0]?.resource_id;
   
      }
  
      // Upload backing track (main)
      if (song.backingTrackFile) {
        const backingTrackResponse = await uploadSongFile(song.backingTrackFile);
        backingTrackId = backingTrackResponse?.data?.resource[0]?.resource_id;
    
      }
  
      // Upload backing track 1
      if (song.backingTrackOneFile) {
        const backingTrackOneResponse = await uploadSongFile(song.backingTrackOneFile);
        backingTrackOneId = backingTrackOneResponse?.data?.resource[0]?.resource_id;
   
      }
  
      // Upload backing track 2
      if (song.backingTrackTwoFile) {
        const backingTrackTwoResponse = await uploadSongFile(song.backingTrackTwoFile);
        backingTrackTwoId = backingTrackTwoResponse?.data?.resource[0]?.resource_id;

      }
  
      // Construct the song object with all the new files and fields
      const newSong = {
        songName: song.name,
        songUrl: song.mainLink,
        karaokeUrl: song.karaokeLink,
        lyricsId: lyrics_id,
        annotationId: annotation_id,
        mainSongId: mainSongId,
        demoTrackId: demoTrackId,
        karaokeAudioId: karaokeAudioId,
        karaokeVideoId: karaokeVideoId,
        backingTrackId: backingTrackId,
        backingTrackOneId: backingTrackOneId,
        backingTrackTwoId: backingTrackTwoId,
        notation_link: song.notation_link,
        kidsFriendly: song.kidFriendly,
        tags: selectedTags.map(tag => tag.value),
      };
  
      setIsUploading(false);
      console.log("New Song Payload:", newSong);
  
      // Add the new song to your database
      await _addSong(newSong);
  
    } catch (error) {
      console.error("Error adding song:", error);
      toast.error('Error adding song');
    }
  };
  
  
  const handleFileChange = (file: File | null, key: string) => {
    if (songToEdit) {
      setSongToEdit({ ...songToEdit, [key]: file });
    }
  };
  

  const toggleCollapse = () => setIsOpen(!isOpen);
  const toggleSongListCollapse = () => setIsSongListOpen(!isSongListOpen);
  const toggleAddCollapse = () => setAddIsOpen(!addIsOpen);

  return (
<Card className="mb-3">
  <CardHeader onClick={toggleSongListCollapse} style={{ cursor: 'pointer' }}>
    <strong>Song List</strong>
    <Button
      color="link"
      onClick={toggleSongListCollapse}
      style={{ float: 'right', padding: '0', textDecoration: 'none' }}
    >
      {isSongListOpen ? '▼' : '►'}
    </Button>
  </CardHeader>
  <Collapse isOpen={isSongListOpen}>
    <CardBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Input
          type="text"
          placeholder="Search by title..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: "250px" }}
        />
      </div>

      {filteredSongs.length > 0 ? (
        <ul style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
          {filteredSongs.map((song,index) => (
           <Card key={song.song_id}>
           <Row className="d-flex align-items-center justify-content-between">
             {/* Title on the left */}
             <Col md="9">
               <strong>Title:</strong> {song.song_name}
             </Col>
         
             {/* Tags section */}
             <Col md="9">
               <strong>Tags:</strong>
               {Array.isArray(song.tags) && song.tags.length > 1 ? (
                 song.tags.map((tag, index) => (
                   <div key={index}>
                     {index + 1}. {tag}
                   </div>
                 ))
               ) : (
                 <span>{song.tags}</span> // If there is only one tag, show it as it is
               )}
             </Col>
            
             <Col md="2" className="d-flex justify-content-end gap-2">
             <Button color="secondary" onClick={() => handleViewSongDetails(song)}>
          <Eye size={18} />
               </Button>
               <Button onClick={() => openDeleteModal(song)}>
                 <Trash size={18} />
               </Button>
               <Button color="link" onClick={() => openEditModal(song)}>
                 <CiEdit size={18} />
               </Button>
             </Col>
           </Row>
         </Card>
         
          ))}
        </ul>
      ) : (
        <p>No songs added yet.</p>
      )}
       <Modal isOpen={modalOpen} toggle={toggleModal} className="custom-modal">
        <ModalHeader toggle={toggleModal}>Song Details</ModalHeader>
        <ModalBody>
          {selectedSong && (
            <ListGroup>
              {songDetails.map(({ key, label, newTab }) =>
                selectedSong[key] ? (
                  <ListGroupItem tag="div" action key={key}>
                    {newTab ? (
                      <a href={selectedSong[key]} target="_blank" rel="noopener noreferrer">
                        {label}
                      </a>
                    ) : (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePreview(selectedSong[key]);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {label}
                      </span>
                    )}
                  </ListGroupItem>
                ) : null
              )}
            </ListGroup>
          )}
        </ModalBody>
      </Modal>
    </CardBody>
  </Collapse>
  {songToEdit && (

<Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
  <ModalHeader toggle={closeEditModal}>Edit Song</ModalHeader>
  <ModalBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
    {/* Shared Fields */}
    <FormGroup>
      <Label for="songName"><b>Song Name</b></Label>
      <Input
        type="text"
        id="songName"
        value={songToEdit?.song_name || ''}
        onChange={(e) => handleInputChange('song_name', e.target.value)}
      />
    </FormGroup>

    {/* Song URL */}
    <FormGroup>
      <Label for="songUrl"><b>Main Song URL</b></Label>
      <Input
        type="url"
        id="songUrl"
        value={songToEdit?.song_url || ''}
        onChange={(e) => handleInputChange('song_url', e.target.value)}
      />
    </FormGroup>

    {/* Karaoke URL */}
    <FormGroup>
      <Label for="karaokeUrl"><b>Karaoke URL</b></Label>
      <Input
        type="url"
        id="karaokeUrl"
        value={songToEdit?.karaoke_url || ''}
        onChange={(e) => handleInputChange('karaoke_url', e.target.value)}
      />
    </FormGroup>

    <FormGroup >
          <Label><b>Karaoke Audio</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"
            // value={songToEdit?.karaokeAudioFile || ''}
            onChange={(e) =>
              handleFileChange(e.target.files ? e.target.files[0] : null, 'karaokeAudioFile')
            }
          

        
          />
        </FormGroup>
        <FormGroup>
          <Label><b>Karaoke Video</b></Label>
          <Input
            type="file"
            accept=".mp3,.wav,.m4a,.mp4"
            // value={songToEdit?.karaokeVideoFile || ''}
            onChange={(e) =>
              handleFileChange(e.target.files ? e.target.files[0] : null, 'karaokeVideoFile')
            }
          
          />
        </FormGroup>
 

    {/* Notation Link */}
    <FormGroup>
      <Label for="notationLink"><b>Notation Link</b></Label>
      <Input
        type="url"
        id="notationLink"
        value={songToEdit?.notation_link || ''}
        onChange={(e) => handleInputChange('notation_link', e.target.value)}
      />
    </FormGroup>

    {/* Upload Main Song File */}
    <FormGroup>
      <Label><b>Upload Main Song File</b></Label>
      <Input
        type="file"
        accept=".mp3,.wav"
        // value={songToEdit?.mainSongFile || ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'mainSongFile')
        }
      />
    </FormGroup>

    {/* Upload Karaoke File */}


    {/* Upload Lyrics File */}
    <FormGroup>
      <Label><b>Upload Lyrics File</b></Label>
      <Input
        type="file"
        accept=".pdf"
        // value={songToEdit?.lyricsFile|| ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'lyricsFile')
        }
      />
    </FormGroup>

    {/* Upload Notation File */}
    <FormGroup>
      <Label><b>Upload Notation File</b></Label>
      <Input
        type="file"
        accept=".pdf"
        // value={songToEdit?.annotationFile|| ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'annotationFile')
        }
      />
    </FormGroup>

    {/* Demo Track */}
    <FormGroup className="mb-3">
      <Label><b>Demo Track</b></Label>
      <Input
        type="file"
        accept=".mp3,.wav,.m4a,.mp4"
        // value={songToEdit?.demoTrackFile|| ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'demoTrackFile')
        }
      />
    </FormGroup>

    {/* Backing Track Original Tempo */}
    <FormGroup className="mb-3">
      <Label><b>Backing Track Original Tempo</b></Label>
      <Input
        type="file"
        accept=".mp3,.wav,.m4a,.mp4"
        // value={songToEdit?.backingTrackFile|| ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'backingTrackFile')
        }
      />
    </FormGroup>

    {/* Backing Track Tempo 1 */}
    <FormGroup className="mb-3">
      <Label><b>Backing Track Tempo 1</b></Label>
      <Input
        type="file"
        accept=".mp3,.wav,.m4a,.mp4"
        // value={songToEdit?.backingTrackOneFile|| ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'backingTrackOneFile')
        }
      />
    </FormGroup>

    {/* Backing Track Tempo 2 */}
    <FormGroup className="mb-3">
      <Label><b>Backing Track Tempo 2</b></Label>
      <Input
        type="file"
        accept=".mp3,.wav,.m4a,.mp4"
        // value={songToEdit?.backingTrackTwoFile|| ''}
        onChange={(e) =>
          handleFileChange(e.target.files ? e.target.files[0] : null, 'backingTrackTwoFile')
        }
      />
    </FormGroup>

    {/* Tags */}
    <FormGroup>
  <Label><b>Select Tags</b></Label>
  <Select
    isMulti
    value={selectedEditTags}
    options={tagOptions}
    onChange={(selectedOptions) => setSelectedEditTags(Array.from(selectedOptions || []))}
    placeholder="Select tags" // Optional: Add a placeholder
  />
</FormGroup>



  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleEditSong}>
      Save Changes
    </Button>
    <Button color="secondary" onClick={closeEditModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>



)}


  <CardHeader onClick={toggleAddCollapse} style={{ cursor: 'pointer' }}>
    <strong>Add Existing Song</strong>
    <Button
      color="link"
      onClick={toggleAddCollapse}
      style={{ float: 'right', padding: '0', textDecoration: 'none' }}
    >
      {addIsOpen ? '▼' : '►'}
    </Button>
  </CardHeader>
  <Collapse isOpen={addIsOpen}>
    <SongSearchComponent setSongs={handleAddExistingSongs} />
  </Collapse>

  <CardHeader onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
    <strong>Add New Song</strong>
    <Button
      color="link"
      onClick={toggleCollapse}
      style={{ float: 'right', padding: '0', textDecoration: 'none' }}
    >
      {isOpen ? '▼' : '►'}
    </Button>
  </CardHeader>
  <Collapse isOpen={isOpen}>
  <FormGroup>
    {/* <Label><b>Select Mode</b></Label> */}
    <Input
    style={{width:"200px", marginTop:"10px"}}
      type="select"
      value={songActive}
      onChange={(e) => toggleSongTab(e.target.value)}
    >
      <option value="1">Add Link</option>
      <option value="2">Upload File</option>
    </Input>
  </FormGroup>
    <CardBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
    {songActive === '1' && (
      <>
      <FormGroup className="mb-3">
        <Label for={`songName`}><b>Enter Song Name </b><span style={{ color: 'red' }}>*</span></Label>
        <Input
          type="text"
          id={`songName`}
          required
          value={song.name}
          onChange={(e) => handleSongChange(0, 'name', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label for={`mainLink`}><b>Main Song Link</b> <span style={{ color: 'red' }}>*</span></Label>
        <Input
          type="url"
          id={`mainLink`}
          required
          value={song.mainLink}
          onChange={(e) => handleSongChange(0, 'mainLink', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label for={`karaokeLink`}><b>Karaoke Song Link</b></Label>
        <Input
    className="form-control"

          type="url"
          id={`karaokeLink`}
          value={song.karaokeLink}
          onChange={(e) => handleSongChange(0, 'karaokeLink', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
  <Label for={`demoTrackFile`} ><b>Demo Track</b></Label>
  <input
    className="form-control"
       ref={fileInputRef}
    type="file"
    accept=".mp3,.wav,.m4a,.mp4"
    onChange={(e) => setSong({ ...song, demoTrackFile: e.target.files ? e.target.files[0] : null })}
  />
</FormGroup>

<FormGroup className="mb-3">
  <Label><b>Backing Track Original Tempo</b></Label>
  <input
         className="form-control"
         ref={fileInputRef}
    type="file"
    accept=".mp3,.wav,.m4a,.mp4"
    onChange={(e) => setSong({ ...song, backingTrackFile: e.target.files ? e.target.files[0] : null })}
  />
</FormGroup>

<FormGroup className="mb-3">
  <Label><b>Backing Track Tempo 1</b></Label>
  <input
       className="form-control"
       ref={fileInputRef}
    type="file"
    accept=".mp3,.wav,.m4a,.mp4"
    onChange={(e) => setSong({ ...song, backingTrackOneFile: e.target.files ? e.target.files[0] : null })}
  />
</FormGroup>

<FormGroup className="mb-3">
  <Label  for={`backingTrackTwoFile`}><b>Backing Track Tempo 2</b></Label>
  <input
       className="form-control"
       ref={fileInputRef}
    type="file"
    id={`backingTrackTwoFile`}
    accept=".mp3,.wav,.m4a,.mp4"
    onChange={(e) => setSong({ ...song, backingTrackTwoFile: e.target.files ? e.target.files[0] : null })}
  />
</FormGroup>

  
<FormGroup className="mb-3">
        <Label for={`lyricsFile`}>Upload Lyrics</Label>
        <input
          className="form-control"
          ref={fileInputRef}
          type="file"
          accept=".pdf"
          id={`lyricsFile`}
          onChange={(e) => setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })}
          />
      </FormGroup>
      <FormGroup className="mb-3">
  <Label for={`uploadAnnotation`}><b>Notation Link</b></Label>
  <Input
  
    type="url"
    id={`uploadAnnotation`}
    value={song.notation_link} // Safe handling for null values

    onChange={(e) => handleSongChange(0, 'notation_link', e.target.value)}
  />
</FormGroup>

<FormGroup className="mb-3">
  <Label for={`annotationFile`}><b>Notation File</b></Label>
  <input
         className="form-control"
         ref={fileInputRef}

     accept=".pdf"
       type="file"
    id={`annotationFile`}

    // onChange={(e) => handleSongChange(0, 'notation_link', e.target.value)}
    onChange={(e) => setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })}

  />
</FormGroup>

    <div>
    <strong>Select Tags:</strong>
    <div>
      <Select
        isMulti
        value={selectedTags}
        options={tagOptions}
        onChange={handleTagChange} 
        placeholder="Select tags"
      />
     
    </div>
  </div>
  </>
    )}
        {songActive === '2' && (
      <>
      <FormGroup className="mb-3">
        <Label for={`songName`}><b>Enter Song Name </b><span style={{ color: 'red' }}>*</span></Label>
        <Input
          type="text"
          
          id={`songName`}
          required
          value={song.name}
          onChange={(e) => handleSongChange(0, 'name', e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-3">
  <Label for={`mainSongFile`} ><b>Main Song File</b></Label>
  <input
         className="form-control"
         ref={fileInputRef}
  id={`mainSongFile`}
    type="file"
    accept=".mp3,.wav,.m4a,.mp4,.mov,.avi,.mkv"
    onChange={(e) => setSong({ ...song, mainSongFile: e.target.files ? e.target.files[0] : null })}
  />
</FormGroup>

<FormGroup className="mb-3">
<Label for= {`demoTrackFile`} ><b>Demo Track</b></Label>
<input
         className="form-control"
         ref={fileInputRef}
  id={`demoTrackFile`}
    type="file"
    accept=".mp3,.wav,.m4a,.mp4,.mov,.avi,.mkv"
    onChange={(e) => setSong({ ...song, demoTrackFile: e.target.files ? e.target.files[0] : null })}
  />
</FormGroup>

      <FormGroup className="mb-3">
          <Label for={`backingTrackFile`} ><b>Backing Track Original Tempo</b></Label>
          <input
         className="form-control"
         ref={fileInputRef}
          id={`backingTrackFile`}
            type="file"
            accept=".mp3,.wav,.m4a,.mp4,.mov,.avi,.mkv"          // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackFile: e.target.files ? e.target.files[0] : null })}
            />
        </FormGroup>

        <FormGroup className="mb-3">
   
          <Label for={`backingTrackOneFile`} ><b>Backing Track Tempo 1</b></Label>
          <input
         className="form-control"
         ref={fileInputRef}
          id={`backingTrackOneFile`}
            type="file"
            accept=".mp3,.wav,.m4a,.mp4,.mov,.avi,.mkv"          // onChange={(e) =>
              onChange={(e) => setSong({ ...song, backingTrackOneFile: e.target.files ? e.target.files[0] : null })}
            
          />
        </FormGroup>
        <FormGroup  className="mb-3">
          <Label for={`backingTrackTwoFile`} ><b>Backing Track Tempo 2</b></Label>
          <input
         className="form-control"
         ref={fileInputRef}
            type="file"
            id={`backingTrackTwoFile`}
            accept=".mp3,.wav,.m4a,.mp4,.mov,.avi,.mkv"      
            onChange={(e) => setSong({ ...song, backingTrackTwoFile: e.target.files ? e.target.files[0] : null })}
  />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for={`araokeAudioFile`} ><b>Karaoke Audio</b></Label>
          <input
         className="form-control"
         ref={fileInputRef}
            type="file"
            id={`karaokeAudioFile`}
            accept=".mp3,.wav,.m4a,.mp4"
            onChange={(e) =>
              setSong({ ...song, karaokeAudioFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label for={`karaokeVideoFile`} ><b>Karaoke Video</b></Label>
          <input
         className="form-control"
         ref={fileInputRef}
         type="file"
                 id={`karaokeVideoFile`}
            accept=".mp3,.wav,.m4a,.mp4"
            onChange={(e) =>
              setSong({ ...song, karaokeVideoFile: e.target.files ? e.target.files[0] : null })
            }
          />
        </FormGroup>
 
      <FormGroup className="mb-3">
        <Label for={`lyricsFile`}><b>Upload Lyrics</b></Label>
        <input
          className="form-control"
          ref={fileInputRef}
          type="file"
          accept=".pdf"
          id={`lyricsFile`}
          onChange={(e) => setSong({ ...song, lyricsFile: e.target.files ? e.target.files[0] : null })}
          />
      </FormGroup>
      <FormGroup className="mb-3">
  <Label for={`uploadAnnotation`}><b>Notation Link</b></Label>
  <Input
  
    type="url"
    id={`uploadAnnotation`}
    value={song.notation_link} // Safe handling for null values

    onChange={(e) => handleSongChange(0, 'notation_link', e.target.value)}
  />
</FormGroup>

<FormGroup className="mb-3">
  <Label for={`annotationFile`}><b>Notation File</b></Label>
  <input
         className="form-control"
         ref={fileInputRef}
     accept=".pdf"
       type="file"
    id={`annotationFile`}

    // onChange={(e) => handleSongChange(0, 'notation_link', e.target.value)}
    onChange={(e) => setSong({ ...song, annotationFile: e.target.files ? e.target.files[0] : null })}

  />
</FormGroup>

    <div>

<FormGroup className="mb-3">
<Label for={`selectedTags`}><b>Select Tags</b></Label>
<Select
        isMulti
   
        options={tagOptions}
        onChange={handleTagChange} 
        placeholder="Select tags"
      />
     
</FormGroup>
    
   
  </div>
  </>
    )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <Button
          disabled={isUploading}
          color="primary"
          onClick={handleAddSong}
          className="mb-3"
        >
          {isUploading ? (
            <Spinner size="sm" /> 
          ) : ('Save')}
        </Button>
      </div>
    </CardBody>
  </Collapse>

  <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal}>
        <ModalHeader toggle={closeDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the song{' '}
          <strong>{songToDelete?.song_name}</strong> from this level?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteSong}>
            Delete
          </Button>
          <Button color="secondary" onClick={closeDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

  {/* Cancel Button */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button className="custom-cancel-button" style={{ marginTop: '20px' }} onClick={toggle}>
      Cancel
    </Button>
  </div>
  {previewUrl && (
    <PreviewModal
      isOpen={isPreviewOpen}
      toggle={closePreview}
      fileUrl={previewUrl}
      title="Preview"
    />
  )}
</Card>

  )};

export default SonglistCard;