import { Card, CardBody, Col, Container, Row, Input, } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { H5, Image, Btn } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import TeacherForm from './AddTeacher/add-teacher';
import { WEB_API_BASEURL } from '../../../Api/api-constant';



// Define the Teacher interface
interface Teacher {
  teacherId: string;
  teacherName: string;
  emailId: string;
  mobileNumber: string;
  profilePhoto?: string;
  credentialId?: string; 
}

const SamplePageContainer: React.FC = () => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    axios.post(`${WEB_API_BASEURL}/admin/teachers`, {
      page: 0, 
      size: 100
    })
      .then(response => {
        const fetchedTeachers: Teacher[] = response.data.content; 
        if (Array.isArray(fetchedTeachers)) {
          setTeachers(fetchedTeachers);
  
          // Extract all credentialIds and store them in local storage
          const credentialIds = fetchedTeachers.map(teacher => teacher.credentialId);
          localStorage.setItem('TeachercredentialIds', JSON.stringify(credentialIds));
        } else {
          console.error('The fetched data is not an array.', fetchedTeachers);
        }
        console.log(fetchedTeachers, 'data');
      })
      .catch(error => {
        console.error('There was an error fetching the teacher data!', error);
      });
  }, []);
  

  // Filter teachers based on the search query
  const filteredTeachers = teachers.filter(teacher =>
    teacher.teacherName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container fluid>
      <Input
        type="text"
        placeholder="Search by teacher name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mb-4"
      />
  <Col xs="12" className="mb-4">
  <Link to={`${process.env.PUBLIC_URL}/pages/TeacherForm`}>
    <Btn color="primary">
      Add Teacher
    </Btn>
  </Link>
</Col>

<Row className="user-cards-items user-card-wrapper">
  {filteredTeachers.map((teacher) => (
    <Col xl="4" sm="6" xxl="3" className="col-ed-4 box-col-4" key={teacher.teacherId}>
    {/* <Link to={`/teacher-view?id=${_.get(teacher, 'teacherId')}&&credid=${_.get(teacher, 'credentialId')}`} state={ teacher }>s */}
        <Card>
          <CardBody>
            <div className="social-img-wrap" style={{ width: "100px", height: "100px" }}>
              <div className="social-img" style={{ width: "100%", height: "100%" }}>
                <Image src={teacher.profilePhoto} className="img-fluid" alt="user" style={{ height: "100%" }} />
              </div>
              <div className="edit-icon"></div>
            </div>
            <div className="social-details">
              <H5 className="mb-1">{teacher.teacherName}</H5>
              <span className="f-light">{teacher.emailId}</span>
              <p className="mb-0">{teacher.mobileNumber}</p>
            </div>
          </CardBody>
        </Card>
      {/* </Link> */}
    </Col>
  ))}
</Row>
    </Container>
  );
};

export default SamplePageContainer;
