import TeacherProfile from "../Pages/Dashboard/Pages";
import StudentProfile from "../Pages/Dashboard/Student/student";
import TeacherForm from "../Componant/Dashboard/Teacher/AddTeacher/add-teacher";
import TeacherCompensationReport from "../Componant/Dashboard/TeacherOperation/TeacherCompensation/reports";
import TeacherForecastWizard from "../Componant/Dashboard/TeacherOperation/TeacherForcast/teacherForcast";
import ProfilePage from "../Componant/Dashboard/Teacher/Profile/profile";
import CourseList from "../Componant/Dashboard/Academics/LMS/course";
import ArtiumCourseManager from "../Componant/Dashboard/Academics/LMS/ArtiumCourseManager";




const routes = [
  { path: `${process.env.PUBLIC_URL}/pages/teacherProfile`, Component: <TeacherProfile /> },
  { path: `${process.env.PUBLIC_URL}/pages/studentprofile`, Component: <StudentProfile /> },
  { path: `${process.env.PUBLIC_URL}/pages/TeacherForm`, Component: <TeacherForm/> },
  { path: `${process.env.PUBLIC_URL}/pages/teacher-compensation`, Component: <TeacherCompensationReport/> },
  { path: `${process.env.PUBLIC_URL}/pages/teacherForcast`, Component: <TeacherForecastWizard/> },
  { path: `${process.env.PUBLIC_URL}/pages/ProfilePage`, Component: <ProfilePage/> },
  { path: `${process.env.PUBLIC_URL}/pages/CourseList`, Component: <CourseList/> },
  // { path: `${process.env.PUBLIC_URL}/pages/ArtiumCourseManager`, Component: <ArtiumCourseManager/> },

  




]

export default routes;