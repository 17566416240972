import React, { useState, useEffect } from 'react';
import {
  addLessonRubrics,
  getRubrics,
  getLessonRubrics,
  removeLessonRubric,
} from '../../../../Api/lms/apiUtils';
import { toast } from 'react-toastify';
import { Trash } from 'react-feather';
import {
  Button,
  ListGroup,
  ListGroupItem,
  FormGroup,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

interface Rubric {
  rubric_id: number;
  rubric_name: string;
}

interface LessonRubricsProps {
  lessonId: any;
  courseDetail: { course_id: number };
}

const LessonRubrics: React.FC<LessonRubricsProps> = ({ courseDetail, lessonId }) => {
  const [rubricsList, setRubricsList] = useState<Rubric[]>([]);
  const [assignedRubrics, setAssignedRubrics] = useState<number[]>([]);
  const [selectedRubrics, setSelectedRubrics] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rubricToDelete, setRubricToDelete] = useState<number | null>(null);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const loadRubrics = async () => {
    try {
      const courseId = courseDetail.course_id;

      const rubricsResponse = await getRubrics(courseId);
      if (rubricsResponse?.rubrics && Array.isArray(rubricsResponse.rubrics)) {
        setRubricsList(rubricsResponse.rubrics);
      } else {
        toast.error('Failed to fetch rubrics list');
      }

      const assignedResponse = await getLessonRubrics(lessonId);
      if (assignedResponse?.rubrics && Array.isArray(assignedResponse.rubrics)) {
        setAssignedRubrics(assignedResponse.rubrics.map((r: Rubric) => r.rubric_id));
      } else {
        toast.error('Failed to fetch assigned rubrics');
      }
    } catch (error) {
      console.error('Error fetching rubrics:', error);
      // toast.error('An error occurred while fetching rubrics');
    }
  };

  const handleAddRubrics = async () => {
    if (selectedRubrics.length === 0) {
      toast.error('Please select at least one rubric to add.');
      return;
    }

    try {
      for (const rubricId of selectedRubrics) {
        const payload = { lessonId, rubricId };
        await addLessonRubrics(payload);
      }
      toast.success('Rubrics added successfully');
      setSelectedRubrics([]);
      loadRubrics();
    } catch (error) {
      console.error('Error adding rubrics to lesson:', error);
      toast.error('Failed to add rubrics');
    }
  };

  const confirmRemoveRubric = (rubricId: number) => {
    setRubricToDelete(rubricId);
    toggleModal();
  };

  const handleRemoveRubric = async () => {
    if (!rubricToDelete) return;

    try {
      const payload = { lessonId, rubricId: rubricToDelete };
      await removeLessonRubric(payload);
      toast.success('Rubric removed successfully');
      loadRubrics();
    } catch (error) {
      console.error('Error removing rubric from lesson:', error);
      toast.error('Failed to remove rubric');
    } finally {
      toggleModal();
      setRubricToDelete(null);
    }
  };

  const handleCheckboxChange = (rubricId: number) => {
    setSelectedRubrics((prevSelected) =>
      prevSelected.includes(rubricId)
        ? prevSelected.filter((id) => id !== rubricId)
        : [...prevSelected, rubricId]
    );
  };

  useEffect(() => {
    loadRubrics();
  }, []);

  return (
    <Container>
  <ListGroup>
  {rubricsList.map((rubric) => (
    <ListGroupItem key={rubric.rubric_id} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <FormGroup check style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Input
          type="checkbox"
          className="levelChk"
          id={`rubric_${rubric.rubric_id}`}
          checked={
            assignedRubrics.includes(rubric.rubric_id) || selectedRubrics.includes(rubric.rubric_id)
          }
          disabled={assignedRubrics.includes(rubric.rubric_id)}
          onChange={() => handleCheckboxChange(rubric.rubric_id)}
        />
        <label
          htmlFor={`rubric_${rubric.rubric_id}`}
          style={{
            color: assignedRubrics.includes(rubric.rubric_id) ? 'gray' : 'black',
            marginLeft: '10px', // Space between checkbox and label
            whiteSpace: 'nowrap', // Prevent label from breaking into multiple lines
            overflow: 'hidden',   // Ensure long rubric names don't overflow
            textOverflow: 'ellipsis',
            marginTop:"10px" // Adds "..." for overflow text if necessary
          }}
        >
          {rubric.rubric_name}
        </label>
      </FormGroup>
      {assignedRubrics.includes(rubric.rubric_id) && (
        <Trash
          size={15}
          style={{ cursor: 'pointer', marginLeft: '10px', marginTop:"10px"  }}
          onClick={() => confirmRemoveRubric(rubric.rubric_id)}
        />
      )}
    </ListGroupItem>
  ))}
</ListGroup>

      <Button
        color="primary"
        onClick={handleAddRubrics}
        disabled={selectedRubrics.length === 0}
        style={{ marginTop: '20px' }}
      >
        Add Rubrics
      </Button>

      {/* Confirmation Modal */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure you want to remove this rubric from the lesson?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleRemoveRubric}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default LessonRubrics;
