import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { CONFIG_BASE_DOMAIN } from '../api-constant';


type ConfigType = {
  
    [key: string]: any;
};


export const ConfigContext = createContext<ConfigType | null>(null);


interface ConfigProviderProps {
    children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
    const [config, setConfig] = useState<ConfigType | null>(null);

    useEffect(() => {
   
        fetch(`${CONFIG_BASE_DOMAIN}/config/uat/config.json`)
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};
