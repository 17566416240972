import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { addRubrics, addCriteriatoRubrics } from '../../../../Api/lms/apiUtils';
import { toast } from 'react-toastify';
import { Criterion } from './rubrics';

type AddRubricsModalProps = {
  isOpen: boolean;
  toggle: () => void;
  courseId: number;
  criteriaList: Criterion[];
  fetchRubrics: () => void;
  // Callback to refresh rubrics list after addition
};

const AddRubricsModal: React.FC<AddRubricsModalProps> = ({ isOpen, toggle, courseId, criteriaList,  fetchRubrics, }) => {
  const [rubricName, setRubricName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCriteria, setSelectedCriteria] = useState<number[]>([]); // Store selected criteria IDs

  // Handle checkbox toggle
  const handleCriteriaSelection = (criteriaId: number) => {
    setSelectedCriteria((prev) =>
      prev.includes(criteriaId)
        ? prev.filter((id) => id !== criteriaId) // Remove if already selected
        : [...prev, criteriaId] // Add if not selected
    );
  };

  // Handle Rubric Creation
  const handleAddRubric = async () => {
    setIsLoading(true);
    let rubricId: number | null = null;

    try {
      const rubricResponse = await addRubrics({ rubricName, courseId });

      if (rubricResponse?.rubric?.rubric_id) {
        rubricId = rubricResponse.rubric.rubric_id;
        toast.success('Rubric added successfully');
        return rubricId;
      } else {
        console.error('Rubric creation failed:', rubricResponse);
        toast.error('Failed to create rubric');
        return null;
      }
    } catch (error) {
      console.error('Error during rubric creation:', error);
      toast.error('Error creating rubric');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Adding Criteria to Rubric
  const handleAddCriteriaToRubric = async (rubricId: number) => {
    try {
      if (selectedCriteria.length > 0) {
        const criteriaResponse = await addCriteriatoRubrics({
          rubricId,
          criteria: selectedCriteria,
        });

        if (criteriaResponse?.message?.toLowerCase().includes('success')) {
          // toast.success('Criteria linked to rubric successfully');
        } else {
          console.error('Criteria linking failed:', criteriaResponse);
          toast.error('Failed to link criteria to rubric');
        }
      }
    } catch (error) {
      console.error('Error during criteria linking:', error);
      toast.error('Error linking criteria to rubric');
    }
  };

  // Unified Save Handler
  const handleSave = async () => {
    if (!rubricName.trim()) {
      toast.error('Rubric name cannot be empty');
      return;
    }

    setIsLoading(true);

    try {
      const rubricId = await handleAddRubric();
      if (rubricId) {
        await handleAddCriteriaToRubric(rubricId);
      }
    } finally {
      setIsLoading(false);
      toggle(); // Close modal
      setRubricName(''); // Reset rubric name
      setSelectedCriteria([]);
      fetchRubrics(); // Clear selected criteria
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Rubrics</ModalHeader>
      <ModalBody style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Form>
          <FormGroup>
            <Label for="rubricName">Rubric Name</Label>
            <Input
              type="text"
              id="rubricName"
              name="rubricName"
              placeholder="Enter rubric name"
              value={rubricName}
              onChange={(e) => setRubricName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Select Criteria</Label>
            <div>
              {criteriaList.map((criterion) => (
                <div key={criterion.criteria_id}>
                  <Input
                    type="checkbox"
                    className="levelChk"
                    id={`criteria-${criterion.criteria_id}`}
                    checked={selectedCriteria.includes(criterion.criteria_id!)}
                    onChange={() => handleCriteriaSelection(criterion.criteria_id!)}
                  />
                  <Label for={`criteria-${criterion.criteria_id}`} className="ms-2">
                    {criterion.criteria_name}
                  </Label>
                </div>
              ))}
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save Rubric'}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRubricsModal;
