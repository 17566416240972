import React, { useState, useEffect } from 'react';
import { addCriteria, editCriteria, getCriteria, getRubrics, deleteRubricCriteria , editRubricsName } from '../../../../Api/lms/apiUtils';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container
} from 'reactstrap';
import { toast } from 'react-toastify';
import { CiEdit,  } from "react-icons/ci";
import {  Eye , Trash} from 'react-feather';
import AddRubricsModal from './RubricsCreation';
import classnames from 'classnames'; // To handle active tab classes
import AddAssignmentCard from './AddAssignment';
import { Add } from '../../../../utils/Constant';

type RubricsProps = {
  selectedCourse: any;
  selectedLevel: any;
  courseLevels: { id: number; name: any }[];
  fetchCourses: () => void;
  courseDetail: any;
  activeTab: any;
};

export type Criterion = {
  criteria_id?: number;
  criteria_name: string;
  criteria_0: string;
  criteria_1: string;
  criteria_2: string;
  criteria_3: string;
  criteria_4: string;
  criteria_5: string;
};

type Rubric = {
  rubric_id: number;
  rubric_name: string;
  course_id: number;
  criteriaNames:any;
  criteria:any;
};

const RubricsCriteria: React.FC<RubricsProps> = ({
  courseDetail,
}) => {
  const [criteriaList, setCriteriaList] = useState<Criterion[]>([]);
  const [rubricsList, setRubricsList] = useState<Rubric[]>([]); 

  const [criteria, setCriteria] = useState<Criterion>({
    criteria_name: '',
    criteria_0:'',
    criteria_1: '',
    criteria_2: '',
    criteria_3: '',
    criteria_4: '',
    criteria_5: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [criteriaModalOpen, setCriteriaModalOpen] = useState<boolean>(false);
  const [rubricModalOpen, setRubricModalOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false); 
  const [selectedPreviewCriteria, setSelectedPreviewCriteria] = useState<Criterion | null>(null);
  const [previewRubricModalOpen, setPreviewRubricModalOpen] = useState<boolean>(false); 
  const [selectedPreviewRubric, setSelectedPreviewRubric] = useState<Rubric | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [criteriaToDelete, setCriteriaToDelete] = useState<{ rubricId: number; criteriaId: number } | null>(null);
  const [isEditingName, setIsEditingName] = useState(false); // Track if the name is being edited
const [newRubricName, setNewRubricName] = useState(''); 



  const toggleCriteriaModal = () => setCriteriaModalOpen(!criteriaModalOpen);
  const toggleRubricModal = () => setRubricModalOpen(!rubricModalOpen);
  const togglePreviewModal = () => setPreviewModalOpen(!previewModalOpen);
  const togglePreviewRubricModal = () => setPreviewRubricModalOpen(!previewRubricModalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);


  // Fetch criteria list for the selected course
  const fetchCriteriaList = async () => {
    const courseId = courseDetail.course_id;
    try {
      const response = await getCriteria(courseId);
      if (response?.criteria && Array.isArray(response.criteria)) {
        setCriteriaList(response.criteria);
      } else {
        toast.error('Failed to fetch criteria list');
      }
    } catch (error) {
      console.error('Error fetching criteria:', error);
      toast.error('Error fetching criteria');
    }
  };

  // Fetch rubrics list for the selected course
  const fetchRubricsList = async () => {
    const courseId = courseDetail.course_id;
    try {
      const response = await getRubrics(courseId);
      if (response?.rubrics && Array.isArray(response.rubrics)) {
        setRubricsList(response.rubrics);
      } else {
        toast.error('Failed to fetch rubrics list');
      }
    } catch (error) {
      console.error('Error fetching rubrics:', error);
  
    }
  };

  useEffect(() => {
    fetchCriteriaList();
    fetchRubricsList();
  }, [courseDetail]);

  const handleDeleteCriteria = (rubricId: number, criteriaId: number) => {
    setCriteriaToDelete({ rubricId, criteriaId }); 
    toggleDeleteModal(); 
    
  };
  
  const confirmDeleteCriteria = async () => {
    if (!criteriaToDelete) return;
  
    const { rubricId, criteriaId } = criteriaToDelete;
  
    try {
      await deleteRubricCriteria(rubricId, criteriaId);
      toast.success('Criterion deleted successfully');
  
      // Update rubricsList state and the selectedPreviewRubric
      setRubricsList((prevRubrics) => {
        return prevRubrics.map((rubric) =>
          rubric.rubric_id === rubricId
            ? {
                ...rubric,
                criteria: rubric.criteria.filter((c: any) => c.criteria_id !== criteriaId),
              }
            : rubric
        );
      });
  
      setSelectedPreviewRubric((prevRubric) => {
        if (!prevRubric) return null;
  
        const updatedCriteria = prevRubric.criteriaNames.filter(
          (criterion: any) => criterion.criteria_id !== criteriaId
        );
  
        // If no criteria left, remove the rubric and close the modal
        if (updatedCriteria.length === 0) {
          setRubricsList((prevRubrics) =>
            prevRubrics.filter((rubric) => rubric.rubric_id !== rubricId)
          );
          togglePreviewRubricModal();
          return null;
        }
  
        // Otherwise, update the rubric in the modal
        return {
          ...prevRubric,
          criteriaNames: updatedCriteria,
        };
      });
    } catch (error) {
      toast.error('Failed to delete criterion');
    } finally {
      toggleDeleteModal();
    }
  };
  
  
  


  const handleCriteriaInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePreviewCriteria = (criterion: Criterion) => {
    setSelectedPreviewCriteria(criterion);
    togglePreviewModal();
  };
  const handlePreviewRubric = (rubric: Rubric) => {
    const associatedCriteria = rubric.criteria.map((c: any) => ({
      criteria_id: c.criteria_id, // Include criteria_id here
      name: c.criteria_name,
      values: [c.criteria_1, c.criteria_2, c.criteria_3, c.criteria_4, c.criteria_5].filter(Boolean),
    }));
  
    setSelectedPreviewRubric({
      ...rubric,
      criteriaNames: associatedCriteria, // Updated structure
    } as Rubric);
  
    togglePreviewRubricModal();
  };
  
  const handleEditRubricName = async () => {
    if (!selectedPreviewRubric) return; // Ensure a rubric is selected
    if (!newRubricName.trim()) {
      toast.error('Rubric name cannot be empty.');
      return;
    }
  
    try {
      // Call the API with the rubric ID and new name
      await editRubricsName(selectedPreviewRubric.rubric_id, { newName: newRubricName });
      toast.success('Rubric name updated successfully');
  
      // Refresh the rubric list
      fetchRubricsList();
  
      // Update the preview modal with the new name
      setSelectedPreviewRubric((prev) => prev ? { ...prev, rubric_name: newRubricName } : null);
  
      // Exit the edit mode
      setIsEditingName(false);
    } catch (error) {
      toast.error('Failed to update rubric name');
    }
  };
  
  const handleSaveCriteria = async () => {
    if (!criteria.criteria_name || [0,1, 2, 3, 4, 5].some(i => !(criteria as any)[`criteria_${i}`])) {
      toast.error('All fields are mandatory.');
      return;
    }
    setIsLoading(true);
  
    try {
      let response;
      const courseId = courseDetail.course_id; // Fetch courseId from courseDetail
      const criteriaId = criteria.criteria_id;
  
      // Construct the request payload based on the mode
      const reqParam = {
        criteriaName: criteria.criteria_name,
        criteria_0:criteria.criteria_0,
        criteria_1: criteria.criteria_1,
        criteria_2: criteria.criteria_2,
        criteria_3: criteria.criteria_3,
        criteria_4: criteria.criteria_4,
        criteria_5: criteria.criteria_5,
      };
  
      if (editMode) {
        // Call editCriteria with courseId in the URL and reqParam in the body
        response = await editCriteria(criteriaId, reqParam);
      } else {
        // Add courseId for adding new criteria
        response = await addCriteria({ ...reqParam, courseId });
      }
  
      // Log the full response for debugging
      console.log('API Response:', response);
  
      // Update success condition based on actual API response structure
      if (response?.data?.message?.toLowerCase().includes('success')) {
        toast.success(editMode ? 'Criterion updated successfully' : 'Criterion added successfully');
        fetchCriteriaList(); // Refresh criteria list
        toggleCriteriaModal();
        resetCriteriaForm();
      } else {
        console.error('Unexpected API response:', response);
        toast.success( editMode ? 'Failed to update criterion' : 'added successfully');
        fetchCriteriaList(); // Refresh criteria list
        toggleCriteriaModal();
        resetCriteriaForm();
      }
    } catch (error) {
      console.error(editMode ? 'Error editing criterion:' : 'Error adding criterion:', error);
      toast.error(editMode ? 'Error editing criterion' : 'Error adding criterion');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const resetCriteriaForm = () => {
    setCriteria({

      criteria_name: '',
      criteria_0:'',
      criteria_1: '',
      criteria_2: '',
      criteria_3: '',
      criteria_4: '',
      criteria_5: '',
    });
    setEditMode(false);
  };

  const handleEditCriteria = (criterion: Criterion) => {
    setCriteria(criterion); // Populate form with criterion data
    setEditMode(true);
    toggleCriteriaModal();
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <div className="mb-3">
        <Button color="primary" onClick={() => { resetCriteriaForm(); toggleCriteriaModal(); }}>
          Add Criteria
        </Button>
        {/* <Button           className={classnames('custom-cancel-button')}
onClick={toggleRubricModal} style={{ marginLeft: '10px' }}>
          Add Rubrics
        </Button> */}
      </div>

      <Container fluid>
        <Row>
          {/* Criteria List */}
          <Col md="6">
            <div className="criteria-list">
              <h3>Criteria List</h3>
              {criteriaList.map((criterion) => (
                <Card key={criterion.criteria_id} className="mb-3">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col>
                        <CardTitle tag="h5" className="mb-0">
                          <b>Name: </b>{criterion.criteria_name}
                        </CardTitle>
                      </Col>
                      <Col xs="auto">
                        {/* <Button style={{marginRight:"20px"}} color="link" onClick={() => handlePreviewCriteria(criterion)} className="p-0">
                   <Eye/>
                        </Button> */}
                        <Button color="link" onClick={() => handleEditCriteria(criterion)} className="p-0">
                          <CiEdit size={18} />
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </div>
          </Col>
          {/* <Col md="6">
          <div className="rubrics-list">
            <h3>Rubrics List</h3>
            {rubricsList.map((rubric) => (
              <Card key={rubric.rubric_id} className="mb-3">
                <CardBody>
                  <Row className="align-items-center">
                    <Col>
                      <CardTitle tag="h5">
                        <b>Name: </b>{rubric.rubric_name}
                      </CardTitle>
                      
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => handlePreviewRubric(rubric)} className="p-0">
                        <Eye />
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
          </div>
        </Col> */}
        </Row>
      </Container>

      {/* Add/Edit Criteria Modal */}
      <Modal isOpen={criteriaModalOpen} toggle={toggleCriteriaModal}>
  <ModalHeader toggle={toggleCriteriaModal}>
    {editMode ? 'Edit Criterion' : 'Add Criterion'}
  </ModalHeader>
  <ModalBody  style={{ maxHeight: '70vh', overflowY: 'auto' }}>
    <Form>
      {/* Criteria Name */}
      <FormGroup>
        <Label for="criteria_name"><b>Criteria Name</b></Label>
        <Input
          type="text"
          id="criteria_name"
          name="criteria_name"
          placeholder="Enter criteria name"
          value={criteria.criteria_name}
          onChange={handleCriteriaInputChange}
          required
          invalid={!criteria.criteria_name} // Highlight invalid fields
        />
      </FormGroup>

      {/* Criteria 1-5 */}
      <>
  {/* Special case for UG/NA */}
  <FormGroup key="criteria_0">
    <Label for="criteria_0">
      <b>UG/NA:</b>
    </Label>
    <Input
      type="text"
      id="criteria_0"
      name="criteria_0"
      placeholder="Enter value for UG/NA"
      value={(criteria as any)["criteria_0"]}
      onChange={handleCriteriaInputChange}
      required
      invalid={!((criteria as any)["criteria_0"])} // Highlight invalid fields
    />
  </FormGroup>

  {/* Loop for Criteria ranges */}
  {[1, 2, 3, 4, 5].map((i) => (
    <FormGroup key={`criteria_${i}`}>
      <Label for={`criteria_${i}`}>
        <b>Criteria {(i * 2) - 1} - {i * 2}:</b>
      </Label>
      <Input
        type="text"
        id={`criteria_${i}`}
        name={`criteria_${i}`}
        placeholder={`Enter value for Criteria ${(i * 2) - 1} - ${i * 2}`}
        value={(criteria as any)[`criteria_${i}`]}
        onChange={handleCriteriaInputChange}
        required
        invalid={!((criteria as any)[`criteria_${i}`])} // Highlight invalid fields
      />
    </FormGroup>
  ))}
</>

    </Form>
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleSaveCriteria} disabled={isLoading}>
      {isLoading ? 'Saving...' : 'Save Criterion'}
    </Button>
    <Button color="secondary" onClick={toggleCriteriaModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>

      <Modal isOpen={previewModalOpen} toggle={togglePreviewModal}>
        <ModalHeader toggle={togglePreviewModal}>Criteria Preview</ModalHeader>
        <ModalBody>
          {selectedPreviewCriteria && (
     <>
     <p><b>Name:</b> {selectedPreviewCriteria.criteria_name}</p>
     {[0, 1, 2, 3, 4, 5].map((i) => (
       <p key={i}>
         <b>
           {i === 0 ? 'UG/NA' : `Criteria ${i}-${i + 1}`}:
         </b> 
         {(selectedPreviewCriteria as any)[`criteria_${i}`]}
       </p>
     ))}
   </>
   
        
          )}
        </ModalBody>
   
      </Modal>
      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this criterion? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDeleteCriteria}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={previewRubricModalOpen} toggle={togglePreviewRubricModal}>
  <ModalHeader toggle={togglePreviewRubricModal}>Rubric Preview</ModalHeader>
  <Modal isOpen={previewRubricModalOpen} toggle={togglePreviewRubricModal}>
  <ModalHeader toggle={togglePreviewRubricModal}>Rubric Preview</ModalHeader>
  <ModalBody style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '10px' }}>
    {selectedPreviewRubric && (
      <>
        {/* Conditionally render the rubric name or an input field */}
        {isEditingName ? (
          <FormGroup>
            <Label for="editRubricName">Rubric Name</Label>
            <Input
              type="text"
              id="editRubricName"
              value={newRubricName}
              onChange={(e) => setNewRubricName(e.target.value)}
              placeholder="Enter new rubric name"
            />
          </FormGroup>
        ) : (
          <p>
            <b>Rubric Name:</b> {selectedPreviewRubric.rubric_name}
            <Button
              color="link"
              size="sm"
              onClick={() => {
                setIsEditingName(true);
                setNewRubricName(selectedPreviewRubric.rubric_name); // Pre-fill with current name
              }}
              className="p-0 ml-2"
            >
              <CiEdit size={16} />
            </Button>
          </p>
        )}

        {/* Display criteria */}
        <ul>
          {selectedPreviewRubric.criteriaNames.map((criterion: any, index: number) => (
            <li key={index}>
              <Row className="align-items-center">
                <Col>
                  <b>{index + 1}. {criterion.name}</b>
                </Col>
                <Col xs="auto">
                <Button
  color="link"
  style={{ color: 'red', padding: 0 }}
  onClick={() => handleDeleteCriteria(selectedPreviewRubric.rubric_id, criterion.criteria_id)}
>
  <Trash size={16} />
</Button>

                </Col>
              </Row>
              <ul>
                {criterion.values.map((value: string, i: number) => (
                  <li key={i}>{value}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </>
    )}
  </ModalBody>
  <ModalFooter>
    {isEditingName && (
      <Button color="primary" onClick={handleEditRubricName}>
        Save
      </Button>
    )}
    <Button color="secondary" onClick={togglePreviewRubricModal}>
      Close
    </Button>
  </ModalFooter>
</Modal>

  <ModalFooter>
    {isEditingName && (
     <Button color="primary" onClick={handleEditRubricName}>
     Save
   </Button>
   
    )}
    <Button color="secondary" onClick={togglePreviewRubricModal}>
      Close
    </Button>
  </ModalFooter>
</Modal>


      {/* Add Rubrics Modal */}
      <AddRubricsModal
        isOpen={rubricModalOpen}
        toggle={toggleRubricModal}
        courseId={courseDetail.course_id}
        criteriaList={criteriaList}
        fetchRubrics={fetchRubricsList} 
      />
    
    </div>
  );
};

export default RubricsCriteria;
