import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ArtiumCourseManager from './ArtiumCourseManager';
import Rubrics from '../LMS/rubrics';
import RubricsCriteria from './rubricksCriteria';

type TabManagerProps = {
  selectedCourse: any;
  selectedLevel: string;
  courseLevels: { id: number; name: string }[];
  fetchCourses: () => void;
  courseDetail: any;
  setSelectedCourse:any
  setCourses:any

};

const TabManager: React.FC<TabManagerProps> = ({
  setCourses,
  setSelectedCourse,
  selectedCourse,
  selectedLevel,
  courseLevels,
  fetchCourses,
  courseDetail,
}) => {
  const [activeTab, setActiveTab] = useState<string>('1');

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      {/* Tab Navigation */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1', default_border: activeTab === '1' })}
            onClick={() => toggleTab('1')}
            style={{ cursor: 'pointer' }}
          >
        Course Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2', default_border: activeTab === '2' })}
            onClick={() => toggleTab('2')}
            style={{ cursor: 'pointer' }}
          >
            Rubrics
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3', default_border: activeTab === '3' })}
            onClick={() => toggleTab('3')}
            style={{ cursor: 'pointer' }}
          >
            Rubrics Criteria
          </NavLink>
        </NavItem>
      </Nav>

      {/* Tab Content */}
      <TabContent activeTab={activeTab}>
        {/* Tab 1: Artium Course Manager */}
        <TabPane tabId="1">
          <ArtiumCourseManager
                setCourses={setCourses}
       setSelectedCourse={setSelectedCourse}
            selectedCourse={selectedCourse}
            selectedLevel={selectedLevel}
            courseLevels={courseLevels}
            fetchCourses={fetchCourses}
            courseDetail={courseDetail}
          />
        </TabPane>

        {/* Tab 2: Rubrics */}
        <TabPane tabId="2">
          <Rubrics

            selectedCourse={selectedCourse}
            selectedLevel={selectedLevel}
            courseLevels={courseLevels}
            fetchCourses={fetchCourses}
            courseDetail={courseDetail}
            activeTab={activeTab}
          />
        </TabPane>
        <TabPane tabId="3">
          <RubricsCriteria
            selectedCourse={selectedCourse}
            selectedLevel={selectedLevel}
            courseLevels={courseLevels}
            fetchCourses={fetchCourses}
            courseDetail={courseDetail}
            activeTab={activeTab}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TabManager;
