import SamplePageContainer from '../../../Componant/Dashboard/Teacher/index'

const TeacherProfile = () => {
  return (
    <div className='page-body'>
      <SamplePageContainer />
    </div>
  )
}

export default TeacherProfile