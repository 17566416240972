import { useState, useEffect } from 'react';
import { getLessonTags } from '../../../../Api/lms/apiUtils';

interface Tag {
  id: number;
  name: string;
}

export const LessontagsMap = () => {
  const [lessonTagsList, setLessonTagsList] = useState<Tag[]>([]);
  const [isLessonLoading, setLessonIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await getLessonTags();
        setLessonTagsList(response.data); 
      } catch (err) {
        setError('Failed to fetch tags');
        console.error('Error fetching tags:', err);
      } finally {
        setLessonIsLoading(false);
      }
    };

    fetchTags();
  }, []);

  return { lessonTagsList, isLessonLoading, error };
};
