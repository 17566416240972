import { addResource, signedURL, uploadResourse } from './apiUtils'; 

export const _addResource = async (resourceFiles: any, displayName:any) => {
    if (resourceFiles) {
        const newResources = Array.from(resourceFiles).map((file:any) => ({
          type: resourceFiles[0].name.split('.').pop(),
          name: (displayName || file.name).split(' ').join('_'), 
          file: file,
        }));
  
        try {
          const fileExtension = resourceFiles[0].name.split('.').pop();
          const response = await signedURL({ fileName: newResources[0].name, fileType:fileExtension });
          if (response.status === 200) {
            (newResources[0] as any).resource_name = response.data.fileName; // Update the resource name to match what the server expects
            const { signedUrl } = response.data;
            const uploadResponse = await uploadResourse(signedUrl, newResources[0].file);
            if (uploadResponse.status === 200) {
              const resourceResponse = await addResource({
                resourceName: (newResources[0] as any).resource_name,
                displayName: displayName || newResources[0].name,
                type: fileExtension,
                description: " ",
                url: " ", 
              });
  
              if (resourceResponse.status === 200) {
                console.log('File uploaded successfully', resourceResponse);
                return resourceResponse.data.resource[0];
              } else {
                console.log('Error adding resource', resourceResponse);
              }
            } else {
              console.log('Error uploading file', uploadResponse);
            }
          }
        } catch (error) {
          console.error('Error during file upload process', error);
        }
      }
}