import { Card, CardBody, Col, Container, Row, Input } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { H5, Image, Btn } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
// import StudentForm from './add-student'; // Assuming there's a similar form for adding students
import { WEB_API_BASEURL } from '../../../Api/api-constant';

// Define the Student interface
interface Student {
  studentId: string;
  studentName: string;
  emailId: string;
  mobileNumber: string;
  profilePhoto?: string;
}

const StudentPageContainer: React.FC = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    axios.post(`${WEB_API_BASEURL}/admin/students`, {
      page: 0,
      size: 100
    })
      .then(response => {
        const fetchedStudents: Student[] = response.data.content;
        if (Array.isArray(fetchedStudents)) {
          setStudents(fetchedStudents);
        } else {
          console.error('The fetched data is not an array.', fetchedStudents);
        }
        console.log(fetchedStudents, 'data');
      })
      .catch(error => {
        console.error('There was an error fetching the student data!', error);
      });
  }, []);

  // Filter students based on the search query
  const filteredStudents = students.filter(student =>
    student.studentName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container fluid>
      <Input
        type="text"
        placeholder="Search by student name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mb-4"
      />
      <Col xs="12" className="mb-4">
        <Link to={`${process.env.PUBLIC_URL}/pages/StudentForm`}>
          <Btn color="primary">
            Add Student
          </Btn>
        </Link>
      </Col>

      <Row className="user-cards-items user-card-wrapper">
        {filteredStudents.map((student) => (
          <Col xl="4" sm="6" xxl="3" className="col-ed-4 box-col-4" key={student.studentId}>
            <Card>
              <CardBody>
                <div className="social-img-wrap" style={{ width: "100px", height: "100px" }}>
                  <div className="social-img" style={{ width: "100%", height: "100%" }}>
                    <Image src={student.profilePhoto} className="img-fluid" alt="user" style={{ height: "100%" }} />
                  </div>
                  <div className="edit-icon">
                  </div>
                </div>
                <div className="social-details">
                  <H5 className="mb-1">
                    {student.studentName}
                  </H5>
                  <span className="f-light">{student.emailId}</span>
                  <p className="mb-0">{student.mobileNumber}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default StudentPageContainer;
