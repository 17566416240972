import { useParams, useLocation } from 'react-router-dom';

function ProfilePage() {
  const { teacherId } = useParams();
  const location = useLocation();
  const teacher = location.state?.teacher;

  // Fetch additional details using teacherId or directly use the passed `teacher` object

  return (
    <div>
      <h1>Profile Page for {teacher?.teacherName}</h1>
      {/* Render the rest of the profile details */}
    </div>
  );
}
export default ProfilePage;