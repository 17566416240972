import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { addLevel, getCourses } from '../../../../Api/lms/apiUtils';
import { toast } from 'react-toastify';

interface Level {
  level_id: number;
  level_name: string;
}

interface Course {
  course_id: number;
  course_name: string;
  levels: Level[];
}

interface AddLevelModalProps {
  setCourses: (course: Course) => void; 
  courseDetail: any;
  courseName: string;
  courseId: number;
  existingLevels: Level[];
  allLevels: { id: number; name: string }[];
  isOpen: boolean;
  toggle: () => void;
  onLevelsAdded: (levels: Level[]) => void;
  setSelectedCourse: (course: Course) => void; // Update course
}

const AddLevelModal: React.FC<AddLevelModalProps> = ({
  setCourses,
  courseDetail,
  courseName,
  courseId,
  existingLevels,
  allLevels,
  isOpen,
  toggle,
  onLevelsAdded,
  setSelectedCourse,
}) => {
  const [selectedLevels, setSelectedLevels] = useState(new Set(existingLevels.map((level) => level.level_name)));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setSelectedLevels(new Set(courseDetail.levels.map((level : any) => level.level_name)));
    }
  }, [courseDetail.levels, isLoading]);

  const handleCheckboxChange = (levelName: string) => {
    const newSelection = new Set(selectedLevels);
    newSelection.has(levelName) ? newSelection.delete(levelName) : newSelection.add(levelName);
    setSelectedLevels(newSelection);
  };

  const fetchCourseLevels = async () => {
    try {
      setIsLoading(true);
      const response = await getCourses();
      if (response.courses) {
        // Update the list of all courses
        setCourses(response.courses);
  
        // Find and update the specific course detail
        const updatedCourse = response.courses.find((course: Course) => course.course_id === courseId);
        if (updatedCourse) {
          setSelectedCourse(updatedCourse);
        }
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
      toast.error('Failed to fetch course details');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSaveLevel = async () => {
    const newLevels = Array.from(selectedLevels).filter(
      (levelName) => !existingLevels.some((level) => level.level_name === levelName)
    );

    setIsLoading(true);
    try {
      const addedLevels: Level[] = [];
      for (const levelName of newLevels) {
        const reqParam = { courseId, levelName, description: '' };
        const response = await addLevel(reqParam);
        if (response.status === 201) {
          toast.success(`Level "${levelName}" added successfully`);
          addedLevels.push({ level_id: response.data.id, level_name: levelName });
        } else {
          console.error('Failed to add level:', response.data.message);
        }
      }

      // Fetch updated levels after successful addition
      await fetchCourseLevels();

      // Pass the newly added levels to the parent component
      onLevelsAdded(addedLevels);

      toggle(); // Close the modal
    } catch (error) {
      console.error('Error adding levels:', error);
      toast.error('Error adding levels');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Level to {courseName}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="courseName">Course Name</Label>
          <Input type="text" id="courseName" value={courseName} disabled />
        </FormGroup>
        <FormGroup>
          {allLevels.map((level) => (
            <div key={level.id} style={{ marginBottom: '10px' }}>
              <Label check>
                <Input
                  type="checkbox"
                  className="levelChk"
                  checked={selectedLevels.has(level.name)}
                  onChange={() => handleCheckboxChange(level.name)}
                  disabled={existingLevels.some((existing) => existing.level_name === level.name)}
                />
                {level.name}
              </Label>
            </div>
          ))}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveLevel} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Add Levels'}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddLevelModal;
