import React, { useState, useEffect, ChangeEvent } from 'react';
import { Col, Row, Form, Label, Input, FormGroup, Card, CardBody, Button } from 'reactstrap';
import axios from 'axios';



const TeacherCompensationReport = () => {


    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
  
    const [email, setEmail] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [dayArray, setDayArray] = useState<string[]>([]);


    const monthArray = [
        { name: 'January', id: '01', day: '16 - 31' },
        { name: 'February', id: '02', day: '16 - 28' },
        // ... (other months)
        { name: 'December', id: '12', day: '16 - 31' }
    ];

    useEffect(() => {
        setDayArray(dayArray); // Update day array when year or month changes
    }, [year, month]);

    const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'year') {
            setYear(value);
            updateDaysForMonth(month, value);
        } else if (name === 'month') {
            setMonth(value);
            updateDaysForMonth(value, year);
        } else if (name === 'day') {
            setDay(value);
        } else if (name === 'email') {
            setEmail(value);
        }
    };

    const updateDaysForMonth = (month: string, year: string) => {
        if (month === '02' && year === '2024') {
            setDayArray(['01 - 15', '16 - 29']);
        } else {
            const selectedMonth = monthArray.find(m => m.id === month);
            const day = selectedMonth?.day || 'N/A'; 
            setDayArray(['01 - 15', day]);
        }
    };
    


    const handleSubmit = async () => {
        setDisabledBtn(true);
        try {
            const fdate = `${year}/${month}/${day.split('-')[0].trim()}`;
            const ldate = `${year}/${month}/${day.split('-')[1].trim()}`;
            const response = await axios.get(`https://uat.artiumacademy.com/api/course/teacherBilling?fromDate=${fdate}&toDate=${ldate}&emailId=${email}`, {
             
            });
            alert(response.data); // Replace with proper notification
        } catch (error) {
            console.error(error);
        } finally {
            setDisabledBtn(false);
        }
    };

    const clearForm = () => {
        setYear('');
        setMonth('');
        setDay('');
        setEmail('');
    };

    return (
        <div className='page-body'>
        <Card className="height-equal">
            <CardBody className="basic-wizard important-validation">
                <h3 className="text-center mb-4">Teacher Compensation Report</h3>
                <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
                    <Row>
                        <Col sm="4" className="mb-3">
                            <Label>Year<span className="txt-danger">*</span></Label>
                            <Input type="select" name="year" value={year} onChange={handleSelectChange} required>
                                <option value="">Select Year</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </Input>
                        </Col>
                        <Col sm="4" className="mb-3">
                            <Label>Month<span className="txt-danger">*</span></Label>
                            <Input type="select" name="month" value={month} onChange={handleSelectChange} required>
                                <option value="">Select Month</option>
                                {monthArray.map((m) => (
                                    <option key={m.id} value={m.id}>{m.name}</option>
                                ))}
                            </Input>
                        </Col>
                        <Col sm="4" className="mb-3">
                            <Label>Day<span className="txt-danger">*</span></Label>
                            <Input type="select" name="day" value={day} onChange={handleSelectChange} required>
                                <option value="">Select Day</option>
                                {dayArray.map((d, index) => (
                                    <option key={index} value={d}>{d}</option>
                                ))}
                            </Input>
                        </Col>
                        <Col sm="12" className="mb-3">
                            <Label>Email<span className="txt-danger">*</span></Label>
                            <Input type="email" name="email" value={email} onChange={handleSelectChange} placeholder="Enter your email" required />
                        </Col>
                    </Row>
                    <div className="wizard-footer d-flex gap-2 justify-content-end">
                        <Button color="secondary" onClick={clearForm}>Clear</Button>
                        <Button color="primary" onClick={handleSubmit} disabled={disabledBtn}>
                            {disabledBtn ? 'Loading...' : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </CardBody>
        </Card>
    </div>
    
    );
};

export default TeacherCompensationReport;
