import React, { useState } from 'react';
import {
  Button, Card, CardBody, FormGroup, Label, Input, Row, Col
} from 'reactstrap';
import { Video, Music, FileText } from 'react-feather';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import './style.scss';

interface Resource {
  type: 'video' | 'audio' | 'pdf';
  name: string;
  displayName: string;  // Added display name field
  url: string;
}

interface AddResourceCardProps {
  onSave: (resources: Resource[], editorContent: string) => void;
  toggle: () => void;  // Toggle function to close the modal
}

const AddResourceCard: React.FC<AddResourceCardProps> = ({ onSave, toggle }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [editorContent, setEditorContent] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [resourceType, setResourceType] = useState<'video' | 'audio' | 'pdf'>('video');
  const [displayNames, setDisplayNames] = useState<{ [key: string]: string }>({});

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles(e.target.files);
    }
  };

  const handleDisplayNameChange = (index: number, displayName: string) => {
    setDisplayNames((prev) => ({ ...prev, [index]: displayName }));
  };

  const handleAddResource = () => {
    if (selectedFiles) {
      const newResources = Array.from(selectedFiles).map((file, index) => ({
        type: resourceType,
        name: file.name,
        displayName: displayNames[index] || file.name,  // Use provided display name or fallback to file name
        url: URL.createObjectURL(file),  // Create a URL for each uploaded file
      }));

      // Add all the new files to the resource list
      setResources([...resources, ...newResources]);
      setSelectedFiles(null);  // Reset the file input for more additions
    }
  };

  const handleSave = () => {
    onSave(resources, editorContent);
  };

  const iconMap = {
    video: <Video color="grey" />,
    audio: <Music color="grey" />,
    pdf: <FileText color="grey" />
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <h5>Add Resources</h5>

        {/* Rich Text Editor */}
        <FormGroup>
          <Label for="resourceContent">Content</Label>
          <ReactQuill value={editorContent} onChange={(content) => setEditorContent(content)} />
        </FormGroup>

        {/* Resource Type Selection */}
        <FormGroup>
          <Label for="resourceType">Select Resource Type</Label>
          <Input type="select" value={resourceType} onChange={(e) => setResourceType(e.target.value as 'video' | 'audio' | 'pdf')}>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
            <option value="pdf">PDF</option>
          </Input>
        </FormGroup>

        {/* Multiple File Upload */}
        <FormGroup>
          <Label for="fileUpload">Upload {resourceType.toUpperCase()}s</Label>
          <Input
            type="file"
            accept={resourceType === 'video' ? 'video/*' : resourceType === 'audio' ? 'audio/*' : 'application/pdf'}
            onChange={handleFileChange}
        
          />
        </FormGroup>

        {/* Display names for each file */}
        {selectedFiles && Array.from(selectedFiles).map((file, index) => (
          <Row key={index} className="mt-2">
            <Col md={6}>
              <Input
                type="text"
                placeholder={`Display name for ${file.name}`}
                value={displayNames[index] || ''}
                onChange={(e) => handleDisplayNameChange(index, e.target.value)}
              />
            </Col>
            <Col md={6}>
              <p>{file.name}</p>
            </Col>
          </Row>
        ))}

        <Button color="primary" onClick={handleAddResource} style={{ marginTop: '1rem' }}>
          Add Resources
        </Button>

        {/* Display Added Resources */}
        <ul style={{ marginTop: '1rem' }}>
          {resources.map((resource, index) => (
            <li key={index}>
              {iconMap[resource.type]} {resource.displayName || resource.name} - <a href={resource.url} target="_blank" rel="noopener noreferrer">View</a>
            </li>
          ))}
        </ul>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Button color="primary" onClick={handleSave} style={{ marginTop: '1rem' }}>
            Save Resources
          </Button>
          <Button className="custom-cancel-button" onClick={toggle} style={{ marginTop: '1rem' }}>
            Cancel
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddResourceCard;
