import { useState, useEffect } from 'react';
import { getTags } from '../../../../Api/lms/apiUtils';

interface Tag {
  id: number;
  name: string;
}

export const tagsMap = () => {
  const [tagsList, setTagsList] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await getTags();
        setTagsList(response.data); // Assuming `response.data` is an array of tags
      } catch (err) {
        setError('Failed to fetch tags');
        console.error('Error fetching tags:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTags();
  }, []);

  return { tagsList, isLoading, error };
};
