import { Breadcrumb, BreadcrumbItem, Col } from 'reactstrap'
import { Link, useLocation, } from 'react-router-dom'
import H1 from '../Headings/H4Element'
import { Image, SVG } from '../../AbstractElements';

import FeatherIcons from '../FeatherIcon/FeatherIcons'

const CommonBreadcrumb = () => {
  const location = useLocation();
  const [firstPart, secondPart,thirdPart] = location.pathname.split("/").slice(1);
  return (
    <Col className="page-title d-flex align-items-center">
    <Image
  
      style={{ marginRight: "10px" }}
      className="img-fluid"
      src="https://artiumacademy.mo.cloudinary.net/v1n/pl_logo_pink.svg"
      alt="Artium Academy Logo"
    />
    <H1 style={{ marginLeft:"10px"}} className="f-w-700 text-capitalize mb-0">Learning Management System</H1>
    
      {/* <Breadcrumb>
        <BreadcrumbItem >
          <Link to={`${process.env.PUBLIC_URL}/pages/samplepage`}>
            <FeatherIcons iconName="Home" />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem >
          <Link to={`${process.env.PUBLIC_URL}/pages/studentList`}>
            <FeatherIcons iconName="Home" />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="f-w-400 text-capitalize">{firstPart.replaceAll("_"," ")}</BreadcrumbItem>
        <BreadcrumbItem className={`f-w-400 ${!thirdPart ?"active" : ""}`}>{secondPart.replaceAll("_"," ")}</BreadcrumbItem>
        {thirdPart && <BreadcrumbItem className="f-w-400 active">{thirdPart.replaceAll("_", " ")}</BreadcrumbItem>}
      </Breadcrumb> */}
    </Col>
  )
}

export default CommonBreadcrumb