import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import './style.scss';
import { LiaChalkboardTeacherSolid } from 'react-icons/lia';
import { PiStudentDuotone } from 'react-icons/pi';
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import LessonRubrics from './lessonRubrics';
import Select from "react-select";
import { LessontagsMap } from './LessonTags'; 

interface AddLessonModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: (newLesson: any) => void;
}

const AddLessonModal: React.FC<AddLessonModalProps> = ({ isOpen, toggle, onSave }) => {
  const [lessonName, setLessonName] = useState<string>('');
  const [lessonNumber, setLessonNumber] = useState<string>('');
  const [lessonSummary, setLessonSummary] = useState<string>('');
  const [lessonObjective, setLessonObjective] = useState<string>('');
  const [learningOutcomes, setLearningOutcomes] = useState<string>('');
  const [lessonProcedure, setLessonProcedure] = useState<string>('');
  const [lessonClosure, setLessonClosure] = useState<string>('');
  const [lessongTags, setLessonTags]= useState<any>('');
  const [error, setError] = useState<string>(''); // Error state to track validation errors
  const {lessonTagsList, isLessonLoading: tagsLoading } = LessontagsMap()
  const LessonTagOptions =lessonTagsList.map((LessonTagMap) => ({ value: LessonTagMap.name, label: LessonTagMap.name }));

  const handleSave = () => {
    // Check if all required fields are filled
    if (
      !lessonNumber ||
      !lessonName ||
      !lessonSummary ||
      !lessonObjective ||
      !learningOutcomes ||
      !lessonProcedure ||
      !lessonClosure 
      // !lessongTags 
    ) {
      toast.error('Please fill all the mandatory fields');
      return;
    }
  
    
    const selectedTagNames = Array.isArray(lessongTags)
    ? lessongTags.map((tag: { value: string; label: string }) => tag.label)
    : [];  
    // Proceed with saving the lesson
    const newLesson = {
      lessonNumber,
      name: lessonName,
      summary: lessonSummary,
      objective: lessonObjective,
      learningOutcomes,
      lessonProcedure,
      lessonClosure,
      lessongTags: selectedTagNames, // Save only the tag names
      topics: [], // Start with an empty topic list
    };
  
    onSave(newLesson);
    toggle(); // Close modal after saving
  };
  
  const toolbarOptions = [
    [{ font: [] }], // Font family
    [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
    ["bold", "italic", "underline", "strike"], // Text styling
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    [{ color: [] }, { background: [] }], // Font color and background
    ["link"], // Links
  ];


  return (
    <Card className="mb-3">
      <CardBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> <h2 style={{color:"#e82c86"}} >Add Lesson</h2>
        <Button style={{ marginLeft: '10px',  color: "#e82c86" }} onClick={toggle}>
         <MdOutlineCancel size={22} />
          </Button></div>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        
        <FormGroup style={{marginTop:"15px"}}>
          <Label for="lessonNumber"> 
            <b  >
              Lesson Number
              <span style={{ color: 'red' }}>*</span>
            </b>
          </Label>
          <Input
            type="text"
            id="lessonNumber"
            value={lessonNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setLessonNumber(value);
              }
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonName"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Lesson Name <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PiStudentDuotone size={20} style={{ marginRight: '10px' }} />
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <Input
            type="text"
            id="lessonName"
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonSummary"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Lesson Summary
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill 
              modules={{
                toolbar: toolbarOptions,
              }}
              formats={[
                "font",
                "script",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "color",
                "background",
                "link",
              ]}
          value={lessonSummary} onChange={setLessonSummary} />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonObjective"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Learning Objective
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PiStudentDuotone size={20} style={{ marginRight: '10px' }} />
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill
              modules={{
                toolbar: toolbarOptions,
              }}
              formats={[
                "font",
                "script",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "color",
                "background",
                "link",
              ]}
           value={lessonObjective} onChange={setLessonObjective} style={{ marginTop: '10px' }} />
        </FormGroup>

        <FormGroup>
          <Label
            for="learningOutcomes"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Learning Outcomes
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill
          modules={{
            toolbar: toolbarOptions,
          }}
          formats={[
            "font",
            "script",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "color",
            "background",
            "link",
          ]}
            style={{ marginTop: '10px' }}
            value={learningOutcomes}
            onChange={setLearningOutcomes}
          />
        </FormGroup>

        <FormGroup>
          <Label
            for="lessonProcedure"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <b>
              Procedure
              <span style={{ color: 'red' }}>*</span>
            </b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LiaChalkboardTeacherSolid size={20} />
            </div>
          </Label>
          <ReactQuill 
          modules={{
            toolbar: toolbarOptions,
          }}
          formats={[
            "font",
            "script",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "color",
            "background",
            "link",
          ]}
          value={lessonProcedure} onChange={setLessonProcedure} style={{ marginTop: '10px' }} />
        </FormGroup>

        <FormGroup>
          <Label for="lessonClosure">
            <b>
              Closure
              <span style={{ color: 'red' }}>*</span>
            </b>
          </Label>
          <ReactQuill
          modules={{
            toolbar: toolbarOptions,
          }}
          formats={[
            "font",
            "script",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "color",
            "background",
            "link",
          ]}
           value={lessonClosure} onChange={setLessonClosure} />
        </FormGroup>
        <FormGroup>
        {/* <Label for="addRubrics">
          
        </Label> */}
        </FormGroup>
        <FormGroup>
        <Label   style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} for="lessonTags">
          
            <b>
          Tags
              {/* <span style={{ color: 'red' }}>*</span> */}
            </b>
          </Label>
        <Select
              isMulti
              value={lessongTags}
              options={LessonTagOptions}
           onChange={setLessonTags}
              placeholder="Select tags"
            />
        </FormGroup>

        <div style={{ marginTop: '10px' }}>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: '10px' }} className="custom-cancel-button" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddLessonModal;
